import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@threeplayground/index';
import Modal from 'react-bootstrap/Modal';

const DeleteConfirmationModal = ({ show, onHide, onConfirm, count }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Are you sure you want to delete {count} pricing block{count !== 1 ? 's' : ''}? This action
          cannot be undone and will affect service pricing.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default DeleteConfirmationModal;
