import {
  AddPartialTranscript,
  AddTranscript,
  EndOfTranscript,
  Info,
  ModelError,
  RealtimeClient,
  RealtimeClientOptions,
  RealtimeServerMessage,
  RecognitionStarted,
  TranscriptionConfig,
  Warning,
} from '@speechmatics/real-time-client';

import { CaptionFormatBuffer } from '../CaptionFormatBuffer/CaptionFormatBuffer';
import { Clock } from '../Clock';
import { Logger } from '~/logic/Logger/Logger';
import { RailsClient } from '~/components/jobs/swatei/RailsClient';
import { RecognizedResult } from '../Recognizer/RecognizedResult';
import { RecognizingResult } from '../Recognizer/RecognizingResult';
import { ResultsStabilityBuffer } from '../Recognizer/ResultsStabilityBuffer';
import { SmxAudioHandler } from './SmxAudioHandler';
import { SetSpeechEngineSessionData, SpeechmaticsConfig, TimestampInMs } from '../_types';

/** The custom punctuation commands */
const customPunctuationCommands = [
  'bam bam',
  'comma',
  'peerk',
  'peermak',
  'kyumak',
  'quex',
  'sklam',
];

/** The captioning interface callbacks */
interface CaptioningInterfaceCallbacks {
  /** A callback to disable the CaptioningInterface's input */
  setCaptionInputDisabled?: (isDisabled: boolean) => void;

  /** A callback to set the speech engine connected state */
  setSpeechEngineConnected?: (state: boolean) => void;

  /** A callback to set the speech engine session data */
  setSpeechEngineSessionData?: SetSpeechEngineSessionData;
}

/** The configuration for the SmxRecognizer */
interface SmxRecognizerConfig {
  /** The audio handler */
  audioHandler?: SmxAudioHandler;

  /**
   * The caption format buffer. If it is not supplied, then the old formatting
   * and buffering logic is used.
   */
  captionFormatBuffer: CaptionFormatBuffer;

  /** The captioning interface callbacks */
  captioningInterfaceCallbacks: CaptioningInterfaceCallbacks;

  /** The Speechmatics configuration */
  speechmaticsConfig: SpeechmaticsConfig;
}

/** Class to interface with the Speechmatics real-time transcription service */
export class SmxRecognizer {
  /** The audio handler */
  private audioHandler: SmxAudioHandler;

  /** The caption format buffer */
  private captionFormatBuffer: CaptionFormatBuffer;

  /** The captioning interface callbacks */
  private captioningInterfaceCallbacks: CaptioningInterfaceCallbacks;

  /** The current Speechmatics real-time transcription client */
  private client!: RealtimeClient;

  /** The current logger */
  private logger: Logger;

  /** The current results buffer */
  private resultsBuffer: ResultsStabilityBuffer | undefined;

  /** The Speechmatics real-time transcription configuration */
  private speechmaticsConfig: SpeechmaticsConfig;

  /** The timestamp when the recognizer started */
  private startTimestamp?: TimestampInMs;

  /** The transcription configuration for the Speechmatics real-time transcription service. */
  private transcriptionConfig: TranscriptionConfig;

  /**
   * Constructor for the SmxRecognizer class
   * @param config The configuration for the SmxRecognizer
   */
  constructor(config: SmxRecognizerConfig) {
    this.audioHandler = config.audioHandler || new SmxAudioHandler();
    this.captionFormatBuffer = config.captionFormatBuffer;
    this.captioningInterfaceCallbacks = config.captioningInterfaceCallbacks;
    this.logger = Logger.getInstance();
    this.speechmaticsConfig = config.speechmaticsConfig;
    this.transcriptionConfig = {
      language: 'en',
      output_locale: 'en-US',
      max_delay: 1.2, // This was honed to be the best balance between latency and accuracy
      enable_partials: true, // These are used to properly disable the caption input as partial transcripts are received
      additional_vocab: [
        ...customPunctuationCommands,
        ...(this.speechmaticsConfig.additionalVocab || []),
      ],
      punctuation_overrides: {
        permitted_marks: [],
        sensitivity: 0,
      },
    };
    this.initializeClient(this.speechmaticsConfig.jwt);
  }

  /**
   * Start the Speechmatics real-time transcription service
   * @returns A promise that resolves when the Speechmatics real-time transcription service is started
   */
  public async start() {
    try {
      // Cleanup any existing audio handlers
      await this.audioHandler.cleanup();
      // Set the start timestamp
      this.startTimestamp = new Clock().now();
      // Prepare the audio handler
      await this.audioHandler.prepareAudio();
      // Start the Speechmatics real-time transcription service
      await this.client.start(this.speechmaticsConfig.jwt, {
        transcription_config: this.transcriptionConfig,
        audio_format: {
          type: 'raw', // Ensure this is the string literal "raw"
          encoding: 'pcm_f32le', // PCM 32-bit floating point, little-endian
          sample_rate: this.audioHandler.getAudioContext()?.sampleRate || 44100, // Use the AudioContext's sample rate
        },
      });
    } catch (error) {
      this.logger.error({
        message: this.formatError(error),
        info: { message: 'Error in SmxRecognizer.start()' },
      });
    }
  }

  /**
   * Stop the Speechmatics real-time transcription service
   * @returns A promise that resolves when the Speechmatics real-time transcription service is stopped
   */
  public async stop() {
    try {
      // Stop the Speechmatics real-time transcription service
      if (this.client) {
        await this.client.stopRecognition();
      }
      // Cleanup the audio handler
      await this.audioHandler.cleanup();
    } catch (error) {
      this.logger.error({
        message: this.formatError(error),
        info: { message: 'Error in SmxRecognizer.stop()' },
      });
    }
  }

  /**
   * Initialize the Speechmatics real-time transcription client
   * @param jwt The JWT token for the Speechmatics real-time transcription service
   */
  private initializeClient(jwt: string) {
    this.logger.info({ message: 'Initializing Speechmatics client' });
    const clientOptions: RealtimeClientOptions = {
      url: `${this.speechmaticsConfig.url}?jwt=${jwt}`,
    };
    // Initialize the Speechmatics real-time transcription client
    this.client = new RealtimeClient(clientOptions);
    // Add event listener for messages from the Speechmatics real-time transcription service
    this.client.addEventListener('receiveMessage', (event: MessageEvent<RealtimeServerMessage>) =>
      this.handleMessage(event.data)
    );
    // Add event listener for socket state changes
    this.client.addEventListener('socketStateChange', (event: Event) =>
      this.handleSocketStateChange(event)
    );
  }

  /**
   * Create a new results buffer
   * @returns The new results buffer
   */
  private createNewResultsBuffer() {
    // Disable caption input
    this.captioningInterfaceCallbacks.setCaptionInputDisabled?.(true);
    return new ResultsStabilityBuffer({
      captionFormatBuffer: this.captionFormatBuffer,
    });
  }

  /**
   * Clear the results buffer
   */
  private clearResultsBuffer() {
    // Enable caption input
    this.captioningInterfaceCallbacks.setCaptionInputDisabled?.(false);
    this.resultsBuffer = undefined;
  }

  /**
   * Get the results buffer
   * @returns The results buffer
   */
  private getResultsBuffer() {
    if (!this.resultsBuffer) {
      this.resultsBuffer = this.createNewResultsBuffer();
    }
    return this.resultsBuffer;
  }

  /**
   * Format an error
   * @param error The error to format
   * @returns The formatted error
   */
  private formatError(error: unknown) {
    if (error instanceof Error) return error;
    return String(error);
  }

  /**
   * Handle a message from the Speechmatics real-time transcription service
   * @param message The message to handle
   */
  private handleMessage(message: RealtimeServerMessage) {
    switch (message.message) {
      case 'RecognitionStarted':
        this.handleRecognitionStarted(message);
        break;
      case 'AudioAdded':
        // NOTE: We are intentionally not doing anything here since this happens continuously
        break;
      case 'AddPartialTranscript':
        this.handleAddPartialTranscript(message);
        break;
      case 'AddTranscript':
        this.handleAddTranscript(message);
        break;
      case 'EndOfTranscript':
        this.handleEndOfTranscript(message);
        break;
      case 'Error':
        this.handleError(message);
        break;
      case 'Warning':
        this.handleWarning(message);
        break;
      case 'Info':
        this.handleInfo(message);
        break;
      // TODO: Determine if we need to handle other message types
      default:
        console.warn('Unhandled message type:', message.message);
    }
  }

  /**
   * Handle a socket state change
   * @param event The event to handle
   */
  private handleSocketStateChange(event: Event) {
    this.logger.info({
      message: 'Socket state changed',
      info: { event, socketState: this.client.socketState },
    });
  }

  /**
   * Handle a RecognitionStarted message
   * @param message The message to handle
   */
  private handleRecognitionStarted(message: RecognitionStarted) {
    try {
      this.logger.info({ message: 'Recognition Started', info: { message } });
      // Send audio data to the Speechmatics real-time transcription service
      this.audioHandler.onAudioData((audioData) => {
        this.client.sendAudio(audioData);
      });
      // Set the speech engine connected state
      this.captioningInterfaceCallbacks.setSpeechEngineConnected?.(true);
      // Set the speech engine session data
      this.captioningInterfaceCallbacks.setSpeechEngineSessionData?.({
        sessionId: message.id,
        speechEngine: 'speechmatics',
      });
    } catch (error) {
      this.logger.error({ message: this.formatError(error), info: { message } });
    }
  }

  /**
   * Handle an AddPartialTranscript message
   * @param message The message to handle
   */
  private handleAddPartialTranscript(message: AddPartialTranscript) {
    this.processTranscript(message, RecognizingResult);
  }

  /**
   * Handle an AddTranscript message
   * @param message The message to handle
   */
  private handleAddTranscript(message: AddTranscript) {
    this.processTranscript(message, RecognizedResult);
    this.clearResultsBuffer();
  }

  /**
   * Handle an EndOfTranscript message
   * @param message The message to handle
   */
  private handleEndOfTranscript(message: EndOfTranscript) {
    try {
      this.logger.info({ message: 'EndOfTranscript', info: { message } });
      this.captioningInterfaceCallbacks.setSpeechEngineConnected?.(false);
      this.captioningInterfaceCallbacks.setSpeechEngineSessionData?.({
        sessionId: null,
        speechEngine: null,
      });
    } catch (error) {
      this.logger.error({ message: this.formatError(error), info: { message } });
    }
  }

  /**
   * Handle an Error message
   * @param message The message to handle
   */
  private handleError(message: ModelError) {
    if (message.type === 'not_authorised') {
      void this.handleNotAuthorised();
    } else {
      this.logger.error({ message: this.formatError(message.reason), info: { message } });
    }
  }

  /**
   * Handle a not authorised message
   */
  private async handleNotAuthorised() {
    this.logger.info({ message: 'Not authorised, reconnecting to Speechmatics' });
    try {
      const jwt = await RailsClient.fetchSpeechmaticsJWT(this.speechmaticsConfig.refreshJwtPath);
      this.initializeClient(jwt);
      await this.start();
    } catch (error) {
      this.logger.error({
        message: this.formatError(error),
        info: { message: 'Error in handleNotAuthorised()' },
      });
    }
  }

  /**
   * Handle a Warning message
   * @param message The message to handle
   */
  private handleWarning(message: Warning) {
    this.logger.warn({ message: 'Speechmatics Warning', info: { message } });
  }

  /**
   * Handle an Info message
   * @param message The message to handle
   */
  private handleInfo(message: Info) {
    this.logger.info({ message: 'Speechmatics Info', info: { message } });
  }

  /**
   * Process a transcript
   * @param message The message to process
   * @param ResultClass The class to use for the result
   */
  private processTranscript(
    message: AddTranscript | AddPartialTranscript,
    ResultClass: typeof RecognizedResult | typeof RecognizingResult
  ) {
    try {
      if (!this.startTimestamp) {
        this.logger.error({
          message: `${ResultClass.name} received before startTimestamp set`,
          info: { message },
        });
        return;
      }
      if (message.metadata.transcript.length === 0) {
        return;
      }
      this.logger.info({ message: ResultClass.name, info: { message } });
      if (message.message === 'AddTranscript') {
        // To avoid needing to define a new stability buffer, we are only adding full (recognized) transcripts
        const result = new ResultClass(this.startTimestamp, {
          resultId: Date.now().toString(),
          offset: message.metadata.start_time * 10_000_000, // Convert seconds to 100-nanosecond increments
          duration: (message.metadata.end_time - message.metadata.start_time) * 10_000_000, // Convert seconds to 100-nanosecond increments
          text: message.metadata.transcript,
          speechEngine: 'speechmatics',
        });
        this.getResultsBuffer().addResult(result);
      } else {
        // We still need to find or create the results buffer for partial (recognizing) transcripts
        // to trigger the logic to properly disable the caption input as partial transcripts are received
        this.getResultsBuffer();
      }
    } catch (error) {
      this.logger.error({ message: this.formatError(error), info: { message } });
    }
  }
}
