import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import { displayPrice } from '~/helpers/numbers';

const SubtitlingOrderOptionsTable = ({ subtitlingOrderData }) => {
  const selectedOrderOptions = subtitlingOrderData?.selectedSubtitlingOptions || [];

  if (selectedOrderOptions.length === 0) {
    return null;
  } else {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Language</th>
            <th>Estimated Cost</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrderOptions.map((orderOption) => {
            return (
              <tr key={orderOption.targetLanguage.id}>
                <td>{orderOption.targetLanguage.fullName}</td>
                <td>{displayPrice(orderOption.price.amount, orderOption.price.unit, false)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
};

SubtitlingOrderOptionsTable.propTypes = {
  subtitlingOrderData: PropTypes.object,
};

export default SubtitlingOrderOptionsTable;
