import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Alert, Card, Form } from 'react-bootstrap';

import { appStyles } from '~/components/app/common/styles';
import OrderMore from '~/components/app/order_more/OrderMore';
import AIDubbingOrderOptions from '~/components/app/order_form/serviceOptions/dubbing/AIDubbingOrderOptions';
import BetaTermsNotice from '~/components/app/order_form/BetaTermsNotice';
import { translationProfileType } from '~/components/app/order_form/propTypes';
import { displayPrice } from '~/helpers/numbers';

const AIDubbingOrderSummary = ({ selectedFormats, transcriptReviewOrdered }) => {
  return (
    <div>
      {transcriptReviewOrdered && <b>Transcript Review Ordered</b>}
      <br />
      <b>AI Dubbing Ordered</b>
      <ul>
        {selectedFormats.map((format) => (
          <li key={format.id}>{format.targetLanguage.fullName}</li>
        ))}
      </ul>
    </div>
  );
};

AIDubbingOrderSummary.propTypes = {
  selectedFormats: PropTypes.arrayOf(PropTypes.any),
  transcriptReviewOrdered: PropTypes.bool,
};

const AIDubbingTipPanel = () => {
  return (
    <>
      <p>
        3Play Media&apos;s AI Dubbing product combines human-edited transcripts and translations
        with best-in-class AI voice matching to produce a premium, cost-effective, and highly
        accurate audio track at scale.
      </p>
      <p>
        Available in multiple languages, this service maximizes human-in-the-loop workflows to
        ensure users receive the optimal combination of people and AI throughout the dubbing
        process.
      </p>
    </>
  );
};

const AIDubbingOrderMore = ({
  authToken,
  betaTermsDisplayDate,
  displayBetaTerms,
  dubbingOrderOptions,
  mediaFiles,
  submissionUrl,
  transcriptReviewPricerate,
  translationProfiles,
}) => {
  // Mimic order form state, so that we can start reusing components.
  const [selectedServices, setSelectedServices] = useState([
    { serviceType: 'Dubbing', selectedDubbingOrderOptions: [] },
  ]);
  const dubbingOrderData =
    selectedServices.find((service) => service.serviceType === 'Dubbing')?.orderOptions || {};
  const selectedDubbingOrderOptions = dubbingOrderData?.selectedDubbingOptions || [];

  const updateOrderOptions = (serviceType, orderOption) => {
    const newSelectedServices = [...selectedServices];
    const serviceIndex = newSelectedServices.findIndex(
      (service) => service.serviceType === serviceType
    );
    if (serviceIndex !== -1) {
      newSelectedServices[serviceIndex] = {
        ...newSelectedServices[serviceIndex],
        orderOptions: orderOption,
      };
    }
    setSelectedServices(newSelectedServices);
  };

  const [betaTermsAccepted, setBetaTermsAccepted] = useState(false);
  const [transcriptReviewOrdered, setTranscripReviewOrdered] = useState(false);

  const onSubmit = ({ setErrors, setSuccess }) => {
    const formData = new FormData();
    formData.append('media_file_ids', mediaFiles.map((file) => file.id).join(','));
    formData.append('beta_terms_accepted', betaTermsAccepted);
    formData.append(
      'selected_dubbing_options',
      selectedDubbingOrderOptions
        .map((option) => {
          return JSON.stringify({
            order_option_id: option.id,
            translation_profile_id: option.translationProfileID,
          });
        })
        .join('|')
    );

    return fetch(submissionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-TOKEN': authToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((currErrors) => [
            ...currErrors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <>
          <Alert className="mt-2" variant="error">
            You cannot proceed with ordering AI Dubbing until all selected files have updated
            speaker IDs. Select the checkbox below to add this service.
          </Alert>
          <Card.Title className={css(appStyles.title)}>Order Transcript Review</Card.Title>
          <Form.Group controlId="transcriptReview">
            <Form.Check
              checked={transcriptReviewOrdered}
              className="d-inline-block"
              onChange={() => setTranscripReviewOrdered((curr) => !curr)}
              type="checkbox"
            />
            This will add speaker IDs to any files without them, at an additional cost of{' '}
            {displayPrice(transcriptReviewPricerate, 'minute', false)}.
          </Form.Group>
          <AIDubbingOrderOptions
            dubbingOrderOptions={dubbingOrderOptions}
            selectedServices={selectedServices}
            showDubbingRefundNotice={false}
            translationProfiles={translationProfiles}
            updateOrderOptions={updateOrderOptions}
          />
          {displayBetaTerms && (
            <BetaTermsNotice
              betaTermsDisplayDate={new Date(betaTermsDisplayDate)}
              betaTermsAccepted={betaTermsAccepted}
              setBetaTermsAccepted={setBetaTermsAccepted}
            />
          )}
        </>
      }
      onSubmit={onSubmit}
      orderType="AI Dubbing"
      submissionDisabled={
        selectedDubbingOrderOptions.length === 0 ||
        (displayBetaTerms && !betaTermsAccepted) ||
        !transcriptReviewOrdered
      }
      summaryContent={
        <AIDubbingOrderSummary
          selectedFormats={selectedDubbingOrderOptions}
          transcriptReviewOrdered={transcriptReviewOrdered}
        />
      }
      tipPanelContent={<AIDubbingTipPanel />}
    />
  );
};

AIDubbingOrderMore.propTypes = {
  authToken: PropTypes.string,
  betaTermsDisplayDate: PropTypes.instanceOf(Date),
  displayBetaTerms: PropTypes.bool,
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.any),
  mediaFiles: PropTypes.arrayOf(PropTypes.any),
  submissionUrl: PropTypes.string,
  transcriptReviewPricerate: PropTypes.number,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
};

export default AIDubbingOrderMore;
