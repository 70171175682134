import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import fuzzysort from 'fuzzysort';

import { Button } from '@threeplayground/index';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AddBlockModal = ({ show, onHide, onSubmit, skus }) => {
  const [formData, setFormData] = useState({
    start_minute: '',
    end_minute: '',
    price: '',
    netsuite_sku: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      start_minute: parseInt(formData.start_minute, 10),
      end_minute: parseInt(formData.end_minute, 10),
      price: parseFloat(formData.price) * 100, // Convert to cents
    });
    handleHide();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSkuSelect = (selected) => {
    if (selected && selected[0]) {
      setFormData((prev) => ({ ...prev, netsuite_sku: selected[0].sku }));
    } else {
      setFormData((prev) => ({ ...prev, netsuite_sku: '' }));
    }
  };

  const handleFilter = (sku, input) => {
    const options = {
      keys: ['name', 'sku'],
      limit: 100,
      threshold: -1000,
    };

    if (input.text.length < 3) {
      return true;
    }
    const found = fuzzysort.go(input.text, [sku], options);
    return found.length > 0;
  };

  const isValid = () => {
    return (
      formData.start_minute !== '' &&
      formData.end_minute !== '' &&
      formData.price !== '' &&
      formData.netsuite_sku !== '' &&
      parseInt(formData.start_minute, 10) >= 0 &&
      parseInt(formData.end_minute, 10) > parseInt(formData.start_minute, 10) &&
      parseFloat(formData.price) > 0
    );
  };

  const handleHide = () => {
    setFormData({
      start_minute: '',
      end_minute: '',
      price: '',
      netsuite_sku: '',
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Pricing Block</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className={css(styles.formGroup)}>
            <Form.Label>SKU</Form.Label>
            <Typeahead
              id="sku-select"
              labelKey={(option) => `${option.name} (${option.sku})`}
              onChange={handleSkuSelect}
              options={skus}
              filterBy={handleFilter}
              placeholder="Search by service name or SKU"
              selected={
                formData.netsuite_sku ? [skus.find((s) => s.sku === formData.netsuite_sku)] : []
              }
              clearButton
            />
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <Form.Label>Start Minute</Form.Label>
            <Form.Control
              type="number"
              name="start_minute"
              value={formData.start_minute}
              onChange={handleChange}
              min="0"
              required
            />
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <Form.Label>End Minute</Form.Label>
            <Form.Control
              type="number"
              name="end_minute"
              value={formData.end_minute}
              onChange={handleChange}
              min="0"
              required
            />
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <Form.Label>Price (in dollars)</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              min="0"
              step="0.01"
              required
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid()}>
            Add Block
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddBlockModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const styles = StyleSheet.create({
  formGroup: {
    marginBottom: '1rem',
  },
});

export default AddBlockModal;
