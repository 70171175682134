import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { submarketRuleShape } from './shapes';

function AllocationRule(props) {
  const pct = Math.round(props.rule.totalAllocation * 100);

  function fallbackLabel() {
    return props.rule.entityType === 'Contractor' ? 'Everyone else' : 'Everything else';
  }

  return (
    <>
      {!props.rule.fallback && (
        <>
          <div className={props.rule.markedForDeletion && css(styles.deleted)}>
            {props.rule.name}
          </div>
          <div className={props.rule.markedForDeletion && css(styles.deleted)}>
            {props.rule.filter}
          </div>
        </>
      )}
      {props.rule.fallback && <div>{fallbackLabel()}</div>}
      <div className={props.rule.valid ? '' : 'text-danger'}>{pct}%</div>
      {!props.rule.fallback && !props.rule.markedForDeletion && (
        <div>
          <Badge variant="secondary" className="mr-2">
            P{props.rule.priority}
          </Badge>
          {props.rule.capturing && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Capturing rule; no further rules will be considered for matching records.
                </Tooltip>
              }
            >
              <i className="fa fa-lock mr-2" />
            </OverlayTrigger>
          )}
          {!props.rule.capturing && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Non-capturing rule; matching records will additionally be evaluated by lower
                  rules.
                </Tooltip>
              }
            >
              <i className="fa fa-unlock mr-2" />
            </OverlayTrigger>
          )}
          <Button variant="outline-secondary" size="sm" onClick={props.onDelete}>
            <i className="fa fa-trash" />
          </Button>
        </div>
      )}
      {props.rule.markedForDeletion && (
        <div>
          <Button variant="outline-secondary" size="sm" onClick={props.onUndelete}>
            <i className="fa fa-undo" />
          </Button>
        </div>
      )}
    </>
  );
}

AllocationRule.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onUndelete: PropTypes.func.isRequired,
  rule: submarketRuleShape.isRequired,
};

const styles = StyleSheet.create({
  deleted: {
    textDecoration: 'line-through',
  },
});

export default AllocationRule;
