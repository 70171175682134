import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import styles from '~/components/app/order_form/serviceDetails/serviceDetailStyles';
import { displayPrice } from '~/helpers/numbers';

const SubtitlingServiceDetails = ({ subtitlingOrderOptions }) => (
  <>
    {subtitlingOrderOptions.map((orderOption) => (
      <div className={css(styles.serviceOptionHeader)} key={orderOption.targetLanguage.id}>
        <span>
          <b>Language</b>: {orderOption.targetLanguage.fullName}
        </span>
        <div className={css(styles.serviceOptionPrice)}>
          {displayPrice(orderOption.price.amount, 'min', false)}
        </div>
      </div>
    ))}
  </>
);

SubtitlingServiceDetails.propTypes = {
  subtitlingOrderOptions: PropTypes.arrayOf(PropTypes.object),
};

export default SubtitlingServiceDetails;
