import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

import { Alert } from '@threeplayground/index';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';

import OrderTemplatesTable from './OrderTemplatesTable';
import OrderTemplatesDetailsModal from './OrderTemplatesDetailsModal';
import OrderTemplatesUpdateModal from './OrderTemplatesUpdateModal';
import OrderTemplatesDeleteModal from './OrderTemplatesDeleteModal';

const OrderTemplatesApp = (props) => {
  const apiClient = new ThreeplayApiV2('/data');

  return (
    <ThreeplayAPIProvider client={apiClient}>
      <OrderTemplatesAppInner {...props} />
    </ThreeplayAPIProvider>
  );
};

const OrderTemplatesAppInner = ({ orderTemplates }) => {
  const [templates, setTemplates] = useState(orderTemplates);
  const [currentlyDisplayingID, setCurrentlyDisplayingID] = useState();
  const [currentlyUpdatingID, setCurrentlyUpdatingID] = useState();
  const [currentlyDeletingID, setCurrentlyDeletingID] = useState();

  const [globalErrors, setGlobalErrors] = useState(undefined);
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState(undefined);

  return (
    <Card>
      <Card.Title className="settings-card-header">Order Templates</Card.Title>
      <div className={css(styles['page'])}>
        {globalSuccessMessage && (
          <Alert className="in" variant="success">
            {globalSuccessMessage}
          </Alert>
        )}
        {globalErrors && <Alert variant="danger">{globalErrors[0].message}</Alert>}
        <Alert className="in" variant="info">
          Order Templates are a way to save your selections on the 3Play Order to use on repeated
          orders. To add a new template, go through the Order Form like you are placing an order and
          stop at Step 3. Then save your Order Template with a name so that you recognize it when
          you place your next order.
        </Alert>
        <OrderTemplatesTable
          orderTemplates={templates}
          setCurrentlyDisplayingID={setCurrentlyDisplayingID}
          setCurrentlyUpdatingID={setCurrentlyUpdatingID}
          setCurrentlyDeletingID={setCurrentlyDeletingID}
        />
        <OrderTemplatesDetailsModal
          handleClose={() => setCurrentlyDisplayingID(null)}
          id={currentlyDisplayingID}
          name={templates.find((t) => t.id === currentlyDisplayingID)?.name}
          template={templates.find((t) => t.id === currentlyDisplayingID)}
        />
        <OrderTemplatesUpdateModal
          handleClose={() => setCurrentlyUpdatingID(null)}
          id={currentlyUpdatingID}
          name={templates.find((t) => t.id === currentlyUpdatingID)?.name}
          setGlobalErrors={setGlobalErrors}
          setGlobalSuccessMessage={setGlobalSuccessMessage}
          setTemplates={setTemplates}
        />
        <OrderTemplatesDeleteModal
          handleClose={() => setCurrentlyDeletingID(null)}
          id={currentlyDeletingID}
          name={templates.find((t) => t.id === currentlyDeletingID)?.name}
          setGlobalErrors={setGlobalErrors}
          setGlobalSuccessMessage={setGlobalSuccessMessage}
          setTemplates={setTemplates}
        />
      </div>
    </Card>
  );
};

OrderTemplatesAppInner.propTypes = {
  orderTemplates: PropTypes.arrayOf(PropTypes.hash),
};

const styles = StyleSheet.create({
  page: {
    width: '100%',
    margin: '0 auto',
    padding: '0 8px',
  },
  'table-heading': {
    'margin-top': '1rem',
  },
});

export default OrderTemplatesApp;
