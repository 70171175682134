import { Clock } from '../Clock';
import { SpeechEngine, TimestampInMs } from '../_types';

/** The recognizer result type */
type RecognizerResultType = 'recognized' | 'recognizing';

/** Recognizer result data */
export interface ResultData {
  /** The result's id from the speech recognizer */
  resultId: string;
  /** The offset of when the speech occured (in 100 nano second increments) */
  offset: number;
  /** The duration of the speech (in 100 nano second increments) */
  duration: number;
  /** The text of the speech */
  text: string;
  /** The confidence of the recognized result */
  confidence?: number;
  /** The speech engine that produced the result */
  speechEngine?: SpeechEngine;
}

/** Class representing a speech recognizer result */
export class RecognizerResult {
  /** The result id from the recognizer */
  result_id: string;
  /** When the captioner audio starts at */
  captioner_audio_starts_at: TimestampInMs;
  /** When the captioner audio ends at */
  captioner_audio_ends_at: TimestampInMs;
  /** When the result comes back from the recognizer */
  recognizer_response_at: TimestampInMs;
  /** The text from the recognizer */
  text_from_recognizer: string;
  /** The recognizer's result confidence */
  confidence?: number;
  /** The recognizer result type */
  result_type: RecognizerResultType;
  /** The speech engine that produced the result */
  speech_engine: SpeechEngine;

  /**
   * Create a speech recognizer result
   * @param {TimestampInMs} start When the speech recognizer was started (timestamp in ms)
   * @param {ResultData} resultData The data for the result from the speech recognizer
   */
  constructor(start: TimestampInMs, resultData: ResultData) {
    this.result_id = resultData.resultId;
    this.captioner_audio_starts_at = start + resultData.offset / 10000; // resultData.offset is in 100 nano second increments
    this.captioner_audio_ends_at = start + (resultData.offset + resultData.duration) / 10000; // resultData.duration is in 100 nano second increments
    this.recognizer_response_at = new Clock().now();
    this.text_from_recognizer = resultData.text;
    this.confidence = resultData.confidence;
    this.result_type = 'recognizing';
    this.speech_engine = resultData.speechEngine || 'microsoft';
  }
}
