export const contractorStatsQuery = `query ContractorStatsQuery($query: String) {
  contractors(query: $query) {
    items {
      id
      name
      availableJobAccessCount
    }
  }
}`;

export const jobStatsQuery = `query JobStats($query: String) {
  jobs(query: $query) {
    items {
      id
      type
      name
      duration
      deadline
      accessCount
      forceList
      loggedInAccessCount
      priorityStars
      project { id name }
      topOfQueue
      service { id type deadline opsDeadline }
      timeOnMarket
    }
    totalDuration
  }
}`;

export const submarketRuleQuery = `query submarketRules{
  submarkets {
    id
    name
    contractorCount
    availableJobCount
  }
  submarketRules {
    id
    entityType
    priority
    capturing
    filter
    fallback
    name
    description
    submarketAllocations {
      submarketId
      allocation
    }
  }
}`;

export const ummV1ControlsQuery = `query UmmV1ControlsQuery($contractorGroupId: Int, $jobTypeId: Int) {
  ummV1Controls (contractorGroupId: $contractorGroupId, jobTypeId: $jobTypeId) {
    items { id contractorGroupId jobTypeId controlType controlValue }
  }
}`;

export const marketManagementControlMatrixQuery = `query MarketManagementControlMatrixQuery {
  marketManagementControlMatrix(
    controlTypes: [
      "total_hour_target",
      "job_count_range",
      "project_cap",
      "deadline_distribution",
      "job_order_randomization",
      "priority_projects",
      "contractor_preference",
    ]
  ) {
    id controlType controlValue jobTypeId contractorGroupId inherited
  }
}`;
