import Kinesis, { Shard } from 'aws-sdk/clients/kinesis';
import { Logger } from '~/logic/Logger/Logger';
import { Caption } from '../CaptionCreator/Caption';
import { CaptionSendingClient } from './_types';

/** The config required for interacting with Kinesis */
interface KinesisClientConfig {
  /** The event's public id; currently stored in SwateiContext */
  eventPublicId: string;

  /**
   * The interval in milliseconds for sending a heartbeat message to Kinesis
   * @default 5000
   */
  heartbeatInterval?: number;

  /** A callback for setting when Kinesis is connected */
  setConnected: (connected: boolean) => void;

  /** The swatei job id */
  swateiJobId: string;
}

// TODO: Find another way to authenticate with Kinesis if these are not client secrets
/** The data returned from /swatei/kinesis_user_credentials  */
interface KinesisUserCredentialsData {
  /** Kinesis access key */
  access: string;

  /** Kinesis secret */
  secret: string;

  /** Kinesis stream name */
  stream_name: string;

  /** Kinesis AWS region */
  region: string;
}

/** The data returned from /swatei/live_events/${swateiJobId}/channel_identifier */
interface ChannelIdentifierData {
  /** The Kinesis shard id */
  shardId: string;
}

/** The data returned from not found response */
interface NotFoundResponse {
  message: string;
  redirect: string;
}

/** A class for interacting with Kinesis */
export class KinesisClient implements CaptionSendingClient {
  /** The Kinesis instance */
  public client: Kinesis | undefined;

  /** The current shard */
  public currentShard: Shard | undefined;

  /** The event's public id */
  private eventPublicId: string;

  /** The interval in milliseconds for sending a heartbeat message to Kinesis  */
  private heartbeatInterval: number;

  /** The interval timer itself for the heartbeat message */
  private heartbeatTimer: NodeJS.Timeout | undefined;

  /** The logger singleton */
  private logger: Logger;

  /** The current Microsoft speech engine session id */
  public speechEngineSessionId: string | null;

  /** A callback for setting when Kinesis is connected */
  private setConnected: (connected: boolean) => void;

  /** The Kinesis stream name */
  public streamName: string | undefined;

  /** The swatei job id */
  private swateiJobId: string;

  constructor({
    eventPublicId,
    heartbeatInterval = 5000,
    setConnected,
    swateiJobId,
  }: KinesisClientConfig) {
    this.eventPublicId = eventPublicId;
    this.heartbeatInterval = heartbeatInterval;
    this.logger = Logger.getInstance();
    this.speechEngineSessionId = null;
    this.setConnected = setConnected;
    this.swateiJobId = swateiJobId;
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx setUpKinesis
  /** Initializes the Kinesis client, sets the shard, and starts the heartbeat */
  async initialize({ startHeartbeat = false } = {}) {
    const url = '/swatei/kinesis_user_credentials';
    this.logger.info({
      message: '3play App kinesis_user_credentials GET request',
      info: { url },
    });
    const response = await fetch(url, { method: 'GET' });
    const {
      access,
      secret,
      stream_name: streamName,
      region,
    } = (await response.json()) as KinesisUserCredentialsData;
    if (!access || !secret) {
      this.logger.warn({
        message: '3play App kinesis_user_credentials GET request missing info',
        info: { response },
      });
      return undefined;
    }
    this.streamName = streamName;
    this.client = new Kinesis({ accessKeyId: access, secretAccessKey: secret, region });
    this.client.listShards({ StreamName: streamName }, (error, data) => {
      if (error) {
        this.logger.error({
          message: 'Kinesis listShards failed',
          info: { streamName },
        });
        return undefined;
      }
      void this.setShard(data);
    });
    this.logger.info({ message: 'Kinesis initialized' });
    if (startHeartbeat) this.startHeartbeat();
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx isKinesisSetup
  /** Whether or not the Kinesis client is ready to send data */
  ready() {
    return Boolean(this.client) && Boolean(this.currentShard) && Boolean(this.streamName);
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx pushCaptionDataToKinesis
  /** Send caption data to Kinesis */
  send(caption: Caption) {
    if (
      this.client !== undefined &&
      this.currentShard !== undefined &&
      this.streamName !== undefined
    ) {
      const data = {
        event_public_id: this.eventPublicId,
        microsoft_session_id: this.speechEngineSessionId,
        swatei_job_id: this.swateiJobId,
        ...caption.dataForKinesis,
      };
      this.logger.info({ message: 'KinesisClient send caption', info: { data } });
      this.client.putRecord(
        {
          Data: JSON.stringify(data),
          ExplicitHashKey: this.currentShardHashKey(),
          PartitionKey: 'overridenByHashKey', // PartitionKey included to comply with SDK validations, but in practice gets overriden by HashKey
          StreamName: this.streamName,
        },
        (error, data) => {
          if (error) {
            this.logger.warn({
              message: 'KinesisClient send caption response error',
              info: { error, data },
            });
            return;
          }

          this.logger.info({
            message: 'KinesisClient send caption response',
            info: { error, data },
          });
        }
      );
    }
  }

  /** Stops the Kinesis heartbeat */
  stopHeartbeat() {
    if (this.heartbeatTimer) clearInterval(this.heartbeatTimer);
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx getShardId
  private async setShard(data: Kinesis.Types.ListShardsOutput) {
    const response = await fetch(`/swatei/live_events/${this.swateiJobId}/channel_identifier`, {
      headers: { Accept: 'application/json' },
      method: 'GET',
    });
    if ([401, 404].includes(response.status)) {
      this.logger.warn({
        message: '3play App channel_identifier triggered redirect',
        info: { response },
      });
      const { redirect } = (await response.json()) as NotFoundResponse;
      window.skipConfirmLeavePage = true;
      window.location.replace(redirect);
    }
    const { shardId } = (await response.json()) as ChannelIdentifierData;
    const shardsInKinesis = data.Shards || [];
    const currentShard = shardsInKinesis.find((shard) => shard.ShardId === shardId);
    if (!currentShard) {
      this.logger.warn({
        message: 'Shard not found in Kinesis',
        info: { shardId },
      });
      return;
    }
    this.currentShard = currentShard;
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx:335
  private startHeartbeat() {
    this.heartbeatTimer = setInterval(() => this.sendHeartbeat(), this.heartbeatInterval);
  }

  // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx sendHeartbeatToKinesis
  private sendHeartbeat() {
    if (
      this.client !== undefined &&
      this.currentShard !== undefined &&
      this.streamName !== undefined
    ) {
      const data = {
        event_public_id: this.eventPublicId,
        heartbeat: 'alive',
        microsoft_session_id: this.speechEngineSessionId,
        swatei_job_id: this.swateiJobId,
      };
      this.logger.info({
        message: 'KinesisClient send heartbeat request',
        info: { data },
      });
      this.client.putRecord(
        {
          Data: JSON.stringify(data),
          ExplicitHashKey: this.currentShardHashKey(),
          PartitionKey: 'overridenByHashKey', // PartitionKey included to comply with SDK validations, but in practice gets overriden by HashKey
          StreamName: this.streamName,
        },
        // From app/javascript/components/jobs/swatei/CaptioningInterface.jsx handleHeartbeatResponse
        (error) => {
          if (error) {
            this.logger.warn({
              message: 'KinesisClient send heartbeat response error',
              info: { error, data },
            });
          }
          this.logger.info({
            message: 'KinesisClient send heartbeat response',
            info: { error, data },
          });
          this.setConnected(!error);
        }
      );
    }
  }

  private currentShardHashKey() {
    return this.currentShard?.HashKeyRange?.StartingHashKey;
  }
}
