import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Card } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { appStyles } from '~/components/app/common/styles';
import { ProjectContext } from '~/components/app/order_form/OrderForm';
import { selectedServicesType } from '~/components/app/order_form/propTypes';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import SubtitlingOrderOptionsTable from '~/components/app/order_form/serviceOptions/subtitling/SubtitlingOrderOptionsTable';

const SubtitlingOrderOptions = ({ selectedServices, updateOrderOptions }) => {
  const { subtitlingOrderOptions } = useContext(ProjectContext);

  const subtitlingOrderData =
    selectedServices.find((service) => service.serviceType === 'Subtitling')?.orderOptions || {};
  const selectedSubtitlingOrderOptions = subtitlingOrderData?.selectedSubtitlingOptions || [];

  const firstOptionPerLanguage = useMemo(() => {
    const languages = {};
    subtitlingOrderOptions.forEach((option) => {
      if (!languages[option.targetLanguage.fullName]) {
        languages[option.targetLanguage.fullName] = option;
      }
    });
    return Object.values(languages).sort((a, b) =>
      a.targetLanguage.fullName.localeCompare(b.targetLanguage.fullName)
    );
  }, [subtitlingOrderOptions]);

  return (
    <OrderOptionsContainer title="Subtitling" componentName="SubtitlingOrderOptions">
      <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
      <Card.Subtitle className="text-muted mb-2">
        Select languages that you want subtitling for:
      </Card.Subtitle>
      <Typeahead
        clearButton
        id="subtitling-language-dropdown"
        multiple={true}
        onChange={(selectedSubtitlingOptions) => {
          updateOrderOptions('Subtitling', { ...subtitlingOrderData, selectedSubtitlingOptions });
        }}
        options={firstOptionPerLanguage}
        labelKey={(item) => item.targetLanguage.fullName}
        placeholder="Search Languages"
        selected={selectedSubtitlingOrderOptions}
      />
      <SubtitlingOrderOptionsTable subtitlingOrderData={subtitlingOrderData} />
    </OrderOptionsContainer>
  );
};

SubtitlingOrderOptions.propTypes = {
  selectedServices: selectedServicesType,
  updateOrderOptions: PropTypes.func,
};

export default SubtitlingOrderOptions;
