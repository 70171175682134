/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AudioFormatRaw
 */
export interface AudioFormatRaw {
  /**
   *
   * @type {string}
   * @memberof AudioFormatRaw
   */
  type: AudioFormatRawTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AudioFormatRaw
   */
  encoding: AudioFormatRawEncodingEnum;
  /**
   *
   * @type {number}
   * @memberof AudioFormatRaw
   */
  sample_rate: number;
}

export const AudioFormatRawTypeEnum = {
  Raw: 'raw',
} as const;

export type AudioFormatRawTypeEnum =
  (typeof AudioFormatRawTypeEnum)[keyof typeof AudioFormatRawTypeEnum];
export const AudioFormatRawEncodingEnum = {
  PcmF32le: 'pcm_f32le',
  PcmS16le: 'pcm_s16le',
  Mulaw: 'mulaw',
} as const;

export type AudioFormatRawEncodingEnum =
  (typeof AudioFormatRawEncodingEnum)[keyof typeof AudioFormatRawEncodingEnum];
