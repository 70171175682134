/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface EndOfTranscript
 */
export interface EndOfTranscript {
  /**
   *
   * @type {string}
   * @memberof EndOfTranscript
   */
  message: EndOfTranscriptMessageEnum;
}

export const EndOfTranscriptMessageEnum = {
  EndOfTranscript: 'EndOfTranscript',
} as const;

export type EndOfTranscriptMessageEnum =
  (typeof EndOfTranscriptMessageEnum)[keyof typeof EndOfTranscriptMessageEnum];
