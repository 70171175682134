import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import Form from 'react-bootstrap/Form';
import { LANGUAGE_SELECTOR_INDEX } from '~/helpers/constants';

function LanguageSelector(props) {
  return (
    <div className="mt-4">
      <Form.Label className={css(styles.stepTitle)}>
        The language spoken in your files is:
      </Form.Label>
      <Form.Control
        as="select"
        tabIndex={props.noTabIndex ? undefined : LANGUAGE_SELECTOR_INDEX}
        onChange={(e) => props.setLanguage(e.target.value)}
        value={props.selectedLanguage}
        className={css(styles.languageInput)}
      >
        {props.languages.map((language) => (
          <option value={language.fullName} key={language.fullName}>
            {language.displayName || language.fullName}
          </option>
        ))}
      </Form.Control>
      <div
        className={
          css(styles.price) +
          (props.selectedLanguage && ['Japanese', 'Hindi'].includes(props.selectedLanguage)
            ? ' language-punctuation-appcues'
            : '')
        }
      >
        {props.price}
      </div>
    </div>
  );
}

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      ids: PropTypes.arrayOf(PropTypes.number),
      fullName: PropTypes.string,
    })
  ),
  selectedLanguage: PropTypes.string,
  setLanguage: PropTypes.func,
  price: PropTypes.string,
  noTabIndex: PropTypes.bool,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
  },
  price: {
    display: 'inline-block',
    paddingLeft: '.25rem',
  },
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
    display: 'block',
  },
  languageInput: {
    width: '20rem',
    display: 'inline-block',
  },
});
export default LanguageSelector;
