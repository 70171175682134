/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import type { RecognitionMetadata } from './recognition-metadata';
// May contain unused imports in some cases
// @ts-ignore
import type { RecognitionResult } from './recognition-result';

/**
 *
 * @export
 * @interface AddPartialTranscript
 */
export interface AddPartialTranscript {
  /**
   *
   * @type {string}
   * @memberof AddPartialTranscript
   */
  message: AddPartialTranscriptMessageEnum;
  /**
   * Speechmatics JSON output format version number.
   * @type {string}
   * @memberof AddPartialTranscript
   */
  format?: string;
  /**
   *
   * @type {RecognitionMetadata}
   * @memberof AddPartialTranscript
   */
  metadata: RecognitionMetadata;
  /**
   *
   * @type {Array<RecognitionResult>}
   * @memberof AddPartialTranscript
   */
  results: Array<RecognitionResult>;
}

export const AddPartialTranscriptMessageEnum = {
  AddPartialTranscript: 'AddPartialTranscript',
} as const;

export type AddPartialTranscriptMessageEnum =
  (typeof AddPartialTranscriptMessageEnum)[keyof typeof AddPartialTranscriptMessageEnum];
