import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Button } from '@threeplayground/index';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import fuzzysort from 'fuzzysort';

const BulkEditModal = ({ show, onHide, onSubmit, skus, selectedCount }) => {
  const [formData, setFormData] = useState({
    start_minute: '',
    end_minute: '',
    price: '',
    netsuite_sku: '',
  });

  const [fieldsToUpdate, setFieldsToUpdate] = useState({
    start_minute: false,
    end_minute: false,
    price: false,
    netsuite_sku: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const updates = {};
    Object.keys(fieldsToUpdate).forEach((field) => {
      if (fieldsToUpdate[field]) {
        let value;
        if (field === 'price') {
          value = parseFloat(formData[field]) * 100; // Convert to cents
        } else if (field === 'start_minute' || field === 'end_minute') {
          value = parseInt(formData[field], 10);
        } else {
          value = formData[field];
        }

        updates[field] = value;
      }
    });
    onSubmit(updates);
    handleHide();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleField = (field) => {
    setFieldsToUpdate((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
    if (!fieldsToUpdate[field]) {
      setFormData((prev) => ({ ...prev, [field]: '' }));
    }
  };

  const isValid = () => {
    const hasSelectedFields = Object.values(fieldsToUpdate).some((v) => v);
    if (!hasSelectedFields) return false;

    return Object.keys(fieldsToUpdate).every((field) => {
      if (!fieldsToUpdate[field]) return true;

      const value = formData[field];
      switch (field) {
        case 'start_minute':
          return value !== '' && parseInt(value, 10) >= 0;
        case 'end_minute':
          return value !== '' && parseInt(value, 10) > 0;
        case 'price':
          return value !== '' && parseFloat(value) > 0;
        case 'netsuite_sku':
          return value !== '';
        default:
          return true;
      }
    });
  };

  const handleSkuSelect = (selected) => {
    if (selected && selected[0]) {
      setFormData((prev) => ({ ...prev, netsuite_sku: selected[0].sku }));
    } else {
      setFormData((prev) => ({ ...prev, netsuite_sku: '' }));
    }
  };

  const handleFilter = (sku, input) => {
    const options = {
      keys: ['name', 'sku'],
      limit: 100,
      threshold: -1000,
    };

    if (input.text.length < 3) {
      return true;
    }
    const found = fuzzysort.go(input.text, [sku], options);
    return found.length > 0;
  };

  const handleHide = () => {
    setFormData({
      start_minute: '',
      end_minute: '',
      price: '',
      netsuite_sku: '',
    });
    setFieldsToUpdate({
      start_minute: false,
      end_minute: false,
      price: false,
      netsuite_sku: false,
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {selectedCount} Pricing Blocks</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Select the fields you want to update. Only selected fields will be changed.</p>

          <Form.Group className={css(styles.formGroup)}>
            <div
              className={css(styles.checkboxContainer)}
              onClick={() => toggleField('netsuite_sku')}
            >
              <Form.Check
                type="checkbox"
                checked={fieldsToUpdate.netsuite_sku}
                // onChange={() => {}}
                label="Update SKU"
              />
            </div>
            {fieldsToUpdate.netsuite_sku && (
              <Typeahead
                id="sku-select"
                labelKey={(option) => `${option.name} (${option.sku})`}
                onChange={handleSkuSelect}
                options={skus}
                filterBy={handleFilter}
                placeholder="Search by service name or SKU"
                selected={
                  formData.netsuite_sku ? [skus.find((s) => s.sku === formData.netsuite_sku)] : []
                }
                clearButton
                className={css(styles.wideIndentedField)}
              />
            )}
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <div
              className={css(styles.checkboxContainer)}
              onClick={() => toggleField('start_minute')}
            >
              <Form.Check
                type="checkbox"
                checked={fieldsToUpdate.start_minute}
                // onChange={() => {}}
                label="Update Start Minute"
              />
            </div>
            {fieldsToUpdate.start_minute && (
              <Form.Control
                type="number"
                name="start_minute"
                value={formData.start_minute}
                onChange={handleChange}
                min="0"
                className={css(styles.indentedField)}
              />
            )}
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <div
              className={css(styles.checkboxContainer)}
              onClick={() => toggleField('end_minute')}
            >
              <Form.Check
                type="checkbox"
                checked={fieldsToUpdate.end_minute}
                // onChange={() => {}}
                label="Update End Minute"
              />
            </div>
            {fieldsToUpdate.end_minute && (
              <Form.Control
                type="number"
                name="end_minute"
                value={formData.end_minute}
                onChange={handleChange}
                min="0"
                className={css(styles.indentedField)}
              />
            )}
          </Form.Group>

          <Form.Group className={css(styles.formGroup)}>
            <div className={css(styles.checkboxContainer)} onClick={() => toggleField('price')}>
              <Form.Check
                type="checkbox"
                checked={fieldsToUpdate.price}
                // onChange={() => {}}
                label="Update Price"
              />
            </div>
            {fieldsToUpdate.price && (
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                min="0"
                step="0.01"
                className={css(styles.indentedField)}
              />
            )}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid()}>
            Update Blocks
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

BulkEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedCount: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  formGroup: {
    marginBottom: '1rem',
  },
  indentedField: {
    marginTop: '0.5rem',
    marginLeft: '1.5rem',
    width: '50%',
  },
  wideIndentedField: {
    marginTop: '0.5rem',
    marginLeft: '1.5rem',
    width: '90%',
  },
  checkboxLabel: {
    width: '100%',
    cursor: 'pointer',
    marginBottom: '0.5rem',
  },
  checkboxContainer: {
    cursor: 'pointer',
    marginBottom: '0.5rem',
    ':hover': {
      backgroundColor: '#f8f9fa', // Optional: adds hover effect
    },
  },
});

export default BulkEditModal;
