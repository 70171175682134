import { Caption } from './CaptionCreator/Caption';
import { CaptionCreator } from './CaptionCreator/CaptionCreator';
import { CaptionFormatBuffer } from './CaptionFormatBuffer/CaptionFormatBuffer';
import { Recognizer } from './Recognizer/Recognizer';
import { SmxRecognizer } from './SmxRecognizer/SmxRecognizer';
import { CaptionFormatConfig, SetSpeechEngineSessionData, SpeechmaticsConfig } from './_types';

/** The config for the CaptionPipeline */
interface CaptionPipelineConfig {
  /**
   * A logger function for amplitude
   *
   * @deprecated Will be replaced by the logger instance
   */
  amplitudeLogger: (message: string, options?: any) => void;

  /**
   * Caption formatting configuration from the server.
   */
  captionFormatConfig: CaptionFormatConfig;

  /** The key for the speech config */
  msCognitiveServiceKey: string;

  /** The endpoint ID for the speech config */
  msCognitiveServiceEndpointId?: string;

  /** A callback to set the value of the CaptioningInterface's input */
  setCaptionInput: (unsentCaptionText: string) => void;

  /** A callback to disable the CaptioningInterface's input */
  setCaptionInputDisabled?: (isDisabled: boolean) => void;

  /** A callback to set the speech engine connected state */
  setSpeechEngineConnected?: (state: boolean) => void;

  /** A callback to set the speechEngineSessionData in the CaptioningInterface */
  setSpeechEngineSessionData: SetSpeechEngineSessionData;

  /** The Speechmatics configuration */
  speechmaticsConfig?: SpeechmaticsConfig;

  /** A function to submit the caption to the CaptioningInterface */
  submitCaption: (caption: Caption) => void;

  /** The word list for the Microsoft speech recognizer  */
  wordlist: string[];

  /** The zero offset for the captions */
  zeroOffsetDateObject: Date;
}

/** A GLOBAL SINGLETON that coordinates the caption pipeline */
export class CaptionPipeline {
  /** The GLOBAL SINGLETON instance */
  private static instance: CaptionPipeline;

  /** The CaptionPipeline's CaptionCreator instance */
  private captionCreator: CaptionCreator;

  /** The CaptionPipeline's CaptionFormatBuffer instance */
  private captionFormatBuffer: CaptionFormatBuffer;

  /** The CaptionPipeline's Recognizer instance */
  private recognizer: Recognizer | SmxRecognizer;

  /** Whether the CaptionPipeline is recognizerStarted */
  public recognizerStarted: boolean;

  private constructor({
    amplitudeLogger,
    captionFormatConfig,
    msCognitiveServiceKey,
    msCognitiveServiceEndpointId,
    setCaptionInput,
    setCaptionInputDisabled,
    setSpeechEngineConnected,
    setSpeechEngineSessionData,
    speechmaticsConfig,
    submitCaption,
    wordlist,
    zeroOffsetDateObject,
  }: CaptionPipelineConfig) {
    this.recognizerStarted = false;
    this.captionCreator = new CaptionCreator({
      submitCaption,
      zeroOffsetDateObject,
    });
    this.captionFormatBuffer = new CaptionFormatBuffer({
      captionCreator: this.captionCreator,
      config: captionFormatConfig,
      setCaptionInput,
    });
    if (speechmaticsConfig) {
      this.recognizer = new SmxRecognizer({
        captionFormatBuffer: this.captionFormatBuffer,
        captioningInterfaceCallbacks: {
          setCaptionInputDisabled,
          setSpeechEngineConnected,
          setSpeechEngineSessionData,
        },
        speechmaticsConfig,
      });
    } else {
      this.recognizer = new Recognizer(
        {
          msCognitiveServiceKey,
          msCognitiveServiceEndpointId,
          setCaptionInputDisabled,
          setSpeechEngineConnected,
          setSpeechEngineSessionData,
          wordlist,
          captionFormatBuffer: this.captionFormatBuffer,
        },
        amplitudeLogger
      );
    }
  }

  /** Gets or creates the global CaptionPipeline instance */
  public static getInstance(
    /**
     * The config to create a CaptionPipeline
     *
     * WARNING: This only used if the CaptionPipeline does not already exist!
     */
    config: CaptionPipelineConfig
  ): CaptionPipeline {
    if (!CaptionPipeline.instance) {
      CaptionPipeline.instance = new CaptionPipeline(config);
    }
    return CaptionPipeline.instance;
  }

  /** Adds text to buffer */
  public addInputTextToBuffer(inputText: string) {
    this.captionFormatBuffer.addInputText(inputText);
  }

  /** Starts the pipeline */
  public startRecognizer() {
    this.recognizerStarted = true;
    void this.recognizer.start();
  }

  /** Stops the pipeline */
  public stopRecognizer() {
    this.recognizerStarted = false;
    void this.recognizer.stop();
  }
}
