import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CloseButton, Modal } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';

const OrderTemplatesDetailsModal = ({ handleClose, id, name, template }) => {
  const closeModal = () => {
    handleClose();
  };

  return (
    <>
      <Modal size="lg" backdrop="static" show={!!id} onHide={closeModal} animation={false}>
        <Modal.Header>
          <Card style={{ border: 0 }}>
            <Card.Title className={css(styles.modalTitle)}>Order Template: {name}</Card.Title>
          </Card>
          <CloseButton onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className={css(styles.batchInfo)}>
            <b>Batch:</b> <span>{template?.batch?.name}</span>
          </div>
          {template?.services.map((service) => (
            <Card className={css(styles.serviceCard)}>
              <Card.Title className={css(styles.serviceCardTitle)}>{service.name}</Card.Title>
              <Card.Body className={css(styles.serviceCardBody)}>
                {service.serviceLevel && (
                  <div>
                    <b>Service Level:</b> <span>{service.serviceLevel}</span>
                  </div>
                )}
                {service.turnaroundLevel && (
                  <div>
                    <b>Turnaround Level:</b> <span>{service.turnaroundLevel}</span>
                  </div>
                )}
                {service.sourceLanguage && (
                  <div>
                    <b>Source Language:</b> <span>{service.sourceLanguage}</span>
                  </div>
                )}
                {service.targetLanguage && (
                  <div>
                    <b>Target Language:</b> <span>{service.targetLanguage}</span>
                  </div>
                )}
                {service.vendor && (
                  <div>
                    <b>Vendor:</b> <span>{service.vendor}</span>
                  </div>
                )}
                {service.orderInstructions && (
                  <div>
                    <b>Order Instructions:</b> <p>{service.orderInstructions}</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#535353',
    marginBottom: '.5rem',
  },
  batchInfo: {
    fontSize: '1.0rem',
    marginLeft: '0.5rem',
  },
  serviceCard: {
    marginTop: '1rem',
  },
  serviceCardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#007eb5',
    padding: '0.5rem 0 0 0.5rem',
  },
  serviceCardBody: {
    padding: '0 1rem 1rem',
  },
});

OrderTemplatesDetailsModal.propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  template: PropTypes.hash,
};

export default OrderTemplatesDetailsModal;
