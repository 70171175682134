import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Button, Table } from '@threeplayground/index';
import { Form } from 'react-bootstrap';
import CsvActionsDropdown from './CsvActionsDropdown';

const PricingBlocksTable = ({
  blocks,
  selectedBlocks,
  onSelectionChange,
  onDeleteClick,
  onAddClick,
  onBulkEditClick,
  onBulkDeleteClick,
  onUpload,
  downloadUrl,
}) => {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      onSelectionChange(blocks.filter((block) => !block.isAutoCreated).map((block) => block.id));
    } else {
      onSelectionChange([]);
    }
  };

  const handleSelect = (blockId) => {
    if (selectedBlocks.includes(blockId)) {
      onSelectionChange(selectedBlocks.filter((id) => id !== blockId));
    } else {
      onSelectionChange([...selectedBlocks, blockId]);
    }
  };

  const nonAutoCreatedBlocks = blocks.filter((block) => !block.isAutoCreated);
  const allSelectableBlocksSelected = selectedBlocks.length === nonAutoCreatedBlocks.length;
  const hasSelectableBlocks = nonAutoCreatedBlocks.length > 0;

  const columns = [
    {
      header: () => (
        <Form.Check
          type="checkbox"
          checked={allSelectableBlocksSelected}
          onChange={handleSelectAll}
          disabled={!hasSelectableBlocks}
        />
      ),
      accessor: 'select',
      cell: ({ rowData }) => (
        <Form.Check
          type="checkbox"
          checked={selectedBlocks.includes(rowData.id)}
          onChange={() => handleSelect(rowData.id)}
          disabled={rowData.isAutoCreated}
        />
      ),
      width: '40px',
    },
    {
      header: 'SKU',
      accessor: 'sku',
      cell: 'string',
    },
    {
      header: 'Block Start',
      accessor: 'startMinute',
      cell: 'number',
    },
    {
      header: 'Block End',
      accessor: 'endMinute',
      cell: 'number',
    },
    {
      header: 'Block Price',
      accessor: 'price',
      cell: 'price',
    },
    {
      header: 'Actions',
      accessor: 'actions',
      cell: ({ rowData }) => {
        return (
          <div className={css(styles.actions)}>
            {!rowData.isAutoCreated && (
              <Button
                variant="danger"
                size="small"
                onClick={() => {
                  onDeleteClick(rowData.id);
                }}
              >
                Delete
              </Button>
            )}
            {rowData.isAutoCreated && <span className={css(styles.autoCreated)}>Auto-created</span>}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className={css(styles.toolbar)}>
        <div className={css(styles.leftActions)}>
          <Button onClick={onAddClick}>Add Block</Button>
          <CsvActionsDropdown onUpload={onUpload} downloadUrl={downloadUrl} />
        </div>
        <div className={css(styles.bulkActions)}>
          <Button
            onClick={onBulkEditClick}
            disabled={selectedBlocks.length === 0}
            variant="secondary"
          >
            Edit Selected ({selectedBlocks.length})
          </Button>
          <Button
            onClick={onBulkDeleteClick}
            disabled={selectedBlocks.length === 0}
            variant="danger"
          >
            Delete Selected ({selectedBlocks.length})
          </Button>
        </div>
      </div>

      <Table columns={columns} data={blocks} />
    </div>
  );
};

PricingBlocksTable.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      startMinute: PropTypes.number.isRequired,
      endMinute: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      isAutoCreated: PropTypes.bool,
    })
  ).isRequired,
  selectedBlocks: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onBulkEditClick: PropTypes.func.isRequired,
  onBulkDeleteClick: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  leftActions: {
    display: 'flex',
    gap: '0.5rem',
  },
  bulkActions: {
    display: 'flex',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    gap: '0.5rem',
  },
  autoCreated: {
    color: '#666',
    fontStyle: 'italic',
  },
});

export default PricingBlocksTable;
