import { RecognizerResult } from './RecognizerResult';
import { SpeechEngine, StableWordData, TimestampInMs } from '../_types';
import { last } from 'lodash';
import { CaptionFormatBuffer } from '../CaptionFormatBuffer/CaptionFormatBuffer';
import { recognizedWordsToAdd } from './recognizedWordsToAdd';

/**
 * The number of times a word must be the same in order to send it out
 *
 * Increasing this value would add latency, but could theoretically increase accuracy.
 *
 * Decreasing this value would reduce latency, but could theoretically decrease accuracy.
 */
const STABILITY_COUNT = 3;

/** The config for a ResultsStabilityBuffer */
interface ResultsStabilityBufferConfig {
  /** A CaptionFormatBuffer instance */
  captionFormatBuffer: CaptionFormatBuffer;
}

/** Metadata for the nth word in the results buffer */
interface ResultBufferWordMetadata {
  /** The index of the word slot */
  insertIndex: number;

  /** The recognizer result id for the first time the word in this "slot" was received */
  resultId: string;

  /** When the captioner audio began for the word in this "slot" */
  captioner_audio_starts_at: TimestampInMs;

  /** When the captioner audio ended for the word in this "slot" */
  captioner_audio_ends_at: TimestampInMs;

  /** When the first recognizer response for the word in this "slot" was received */
  first_recognizer_response_at: TimestampInMs;

  /** When the last recognizer response for the word in this "slot" was received */
  last_recognizer_response_at: TimestampInMs;

  /** The result type of the last recognizer response */
  result_type: string;

  /** The speech engine that produced the result */
  speech_engine: SpeechEngine;

  /** The different words that have appeared in this "slot" */
  occurrences: string[];
}

/**
 * A buffer of recognizer results. A new ResultsStabilityBuffer is created each time
 * the recognizer sends a recognizing result.
 */
export class ResultsStabilityBuffer {
  /** The passed in CaptionFormatBuffer instance */
  private captionFormatBuffer: CaptionFormatBuffer;

  /** The index of the last word submitted from the results buffer */
  private indexOfLastWordSent: number;

  /** The results received from the recognizer */
  private results: RecognizerResult[];

  /** An array with metadata about all of the words in the results buffer */
  private wordArray: ResultBufferWordMetadata[];

  /** Create a results buffer */
  constructor({ captionFormatBuffer }: ResultsStabilityBufferConfig) {
    this.captionFormatBuffer = captionFormatBuffer;
    this.indexOfLastWordSent = -1;
    this.results = [];
    this.wordArray = [];
  }

  /**
   * Adds the recognizer result to the buffer and submits the results in the buffer
   * to the captionFormatBuffer with their metadata (as necessary)
   */
  addResult(newResult: RecognizerResult) {
    this.results.push(newResult);
    this.updateWordArray(newResult);
    const stableWordData = this.getStableWordData();
    const stableWordDataForBuffer: StableWordData[] = stableWordData.map(
      ({
        captioner_audio_starts_at,
        captioner_audio_ends_at,
        first_recognizer_response_at,
        last_recognizer_response_at,
        result_type,
        speech_engine,
        occurrences,
      }) => ({
        captioner_audio_starts_at,
        captioner_audio_ends_at,
        first_recognizer_response_at,
        sent_recognizer_response_at: last_recognizer_response_at,
        result_type,
        speech_engine,
        word: last(occurrences) || '',
      })
    );
    if (stableWordDataForBuffer.length > 0) {
      this.captionFormatBuffer.add(stableWordDataForBuffer);
      const lastInsertIndex = last(stableWordData)?.insertIndex;
      this.indexOfLastWordSent = lastInsertIndex !== undefined ? lastInsertIndex : -1;
    }
  }

  /** Updates the array that tracks the stability and timing of every word received */
  private updateWordArray(result: RecognizerResult) {
    const newWords = result.text_from_recognizer.split(' ');

    if (result.result_type === 'recognized') {
      const newWordsToAdd = recognizedWordsToAdd(
        this.wordArray.map(({ occurrences }) => last(occurrences) || ''),
        newWords
      );
      newWordsToAdd.forEach((word, index) => {
        this.wordArray.push({
          insertIndex: this.wordArray.length + index,
          resultId: result.result_id,
          captioner_audio_starts_at:
            this.wordArray[this.wordArray.length + index - 1]?.captioner_audio_ends_at ||
            result.captioner_audio_starts_at,
          captioner_audio_ends_at: result.captioner_audio_ends_at,
          first_recognizer_response_at: result.recognizer_response_at,
          last_recognizer_response_at: result.recognizer_response_at,
          result_type: result.result_type,
          speech_engine: result.speech_engine,
          occurrences: [word],
        });
      });
    } else {
      newWords.forEach((word, index) => {
        const existingWordData = this.wordArray[index];
        this.wordArray[index] = {
          insertIndex: existingWordData?.insertIndex || index,
          resultId: existingWordData?.resultId || result.result_id,
          captioner_audio_starts_at:
            existingWordData?.captioner_audio_starts_at ||
            this.wordArray[index - 1]?.captioner_audio_ends_at ||
            result.captioner_audio_starts_at,
          captioner_audio_ends_at:
            existingWordData?.captioner_audio_ends_at || result.captioner_audio_ends_at,
          first_recognizer_response_at:
            existingWordData?.first_recognizer_response_at || result.recognizer_response_at,
          last_recognizer_response_at: result.recognizer_response_at,
          result_type: result.result_type,
          speech_engine: result.speech_engine,
          occurrences: [...(this.wordArray[index]?.occurrences || []), word],
        };
      });
    }
  }

  private lastResultRecognized() {
    return Boolean(last(this.results)) && last(this.results)?.result_type === 'recognized';
  }

  private getStableWordData() {
    const unsentWords = this.wordArray.slice(this.indexOfLastWordSent + 1);
    if (this.lastResultRecognized()) return unsentWords;

    const stableWordData: ResultBufferWordMetadata[] = [];
    unsentWords.every((unsentWordData) => {
      const lastNMatches = unsentWordData.occurrences.slice(-1 * STABILITY_COUNT);
      if (
        lastNMatches.length === STABILITY_COUNT &&
        lastNMatches.every((word) => word === lastNMatches[0])
      ) {
        stableWordData.push(unsentWordData);
        return true;
      }
      return false;
    });

    return stableWordData;
  }
}
