/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import type { AudioEventsConfig } from './audio-events-config';
// May contain unused imports in some cases
// @ts-ignore
import type { AudioFormat } from './audio-format';
// May contain unused imports in some cases
// @ts-ignore
import type { TranscriptionConfig } from './transcription-config';
// May contain unused imports in some cases
// @ts-ignore
import type { TranslationConfig } from './translation-config';

/**
 *
 * @export
 * @interface StartRecognition
 */
export interface StartRecognition {
  /**
   *
   * @type {string}
   * @memberof StartRecognition
   */
  message: StartRecognitionMessageEnum;
  /**
   *
   * @type {AudioFormat}
   * @memberof StartRecognition
   */
  audio_format: AudioFormat;
  /**
   *
   * @type {TranscriptionConfig}
   * @memberof StartRecognition
   */
  transcription_config: TranscriptionConfig;
  /**
   *
   * @type {TranslationConfig}
   * @memberof StartRecognition
   */
  translation_config?: TranslationConfig;
  /**
   *
   * @type {AudioEventsConfig}
   * @memberof StartRecognition
   */
  audio_events_config?: AudioEventsConfig;
}

export const StartRecognitionMessageEnum = {
  StartRecognition: 'StartRecognition',
} as const;

export type StartRecognitionMessageEnum =
  (typeof StartRecognitionMessageEnum)[keyof typeof StartRecognitionMessageEnum];
