import { Logger } from '~/logic/Logger/Logger';

interface AddSessionIdParams {
  session_id: string;
  speech_engine: string;
}

export class RailsResponseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RailsResponseError';
  }
}

export class RailsDataShapeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RailsDataShapeError';
  }
}

export class RailsClient {
  private logger: Logger;

  constructor() {
    this.logger = Logger.getInstance();
  }

  static async addSpeechEngineSessionId(path: string, params: AddSessionIdParams): Promise<void> {
    const client = new RailsClient();
    return client.addSpeechEngineSessionId(path, params);
  }

  static async fetchSpeechmaticsJWT(path: string): Promise<string> {
    const client = new RailsClient();
    return client.fetchSpeechmaticsJWT(path);
  }

  async addSpeechEngineSessionId(path: string, params: AddSessionIdParams): Promise<void> {
    await this.performFetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
  }

  async fetchSpeechmaticsJWT(path: string): Promise<string> {
    const data = await this.performFetch(path);
    if (typeof data === 'object' && data !== null && 'jwt' in data) {
      return (data as { jwt: string }).jwt;
    }
    throw new RailsDataShapeError('Invalid response format: Expected { jwt: string }');
  }

  private getCsrfToken(): string | null {
    return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || null;
  }

  private async performFetch(path: string, init?: RequestInit): Promise<unknown> {
    try {
      const csrfToken = this.getCsrfToken();
      const headers = {
        ...(init?.headers || {}),
        'X-CSRF-Token': csrfToken || '',
      };

      const finalInit = {
        ...init,
        headers,
      };

      this.logger.info({ message: 'Fetching from', info: { path, init: finalInit } });
      const response = await fetch(path, finalInit);

      this.logger.info({ message: 'Response status', info: { status: response.status } });

      if (!response.ok) {
        throw new RailsResponseError(`Failed to fetch: ${response.statusText}`);
      }

      const data = (await response.json()) as unknown;
      this.logger.info({ message: 'Response data', info: { data } });
      return data;
    } catch (error) {
      this.logger.error({ message: 'Error during fetch', info: { error } });
      throw error;
    }
  }
}
