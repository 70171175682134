/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Info
 */
export interface Info {
  /**
   *
   * @type {string}
   * @memberof Info
   */
  message: InfoMessageEnum;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  type: InfoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  reason: string;
  /**
   *
   * @type {number}
   * @memberof Info
   */
  code?: number;
  /**
   *
   * @type {number}
   * @memberof Info
   */
  seq_no?: number;
  /**
   *
   * @type {string}
   * @memberof Info
   */
  quality?: string;
}

export const InfoMessageEnum = {
  Info: 'Info',
} as const;

export type InfoMessageEnum =
  (typeof InfoMessageEnum)[keyof typeof InfoMessageEnum];
export const InfoTypeEnum = {
  RecognitionQuality: 'recognition_quality',
  ModelRedirect: 'model_redirect',
  Deprecated: 'deprecated',
} as const;

export type InfoTypeEnum = (typeof InfoTypeEnum)[keyof typeof InfoTypeEnum];
