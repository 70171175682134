import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fuzzysort from 'fuzzysort';
import { css, StyleSheet } from 'aphrodite';

import Card from 'react-bootstrap/Card';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import SkuTable from './SkuTable';

const SkuLookup = ({ skus }) => {
  const [selectedSKUs, setSelectedSKUs] = useState([]);

  const handleFilter = (sku, input) => {
    // Lookup a SKU by service, or a service by SKU
    const options = {
      keys: ['name', 'sku'],
      limit: 100,
      threshold: -1000,
    };

    if (input.text.length < 3) {
      return true;
    }
    const found = fuzzysort.go(input.text, [sku], options);
    return found.length > 0;
  };

  return (
    <>
      <Card.Title>SKU Lookup</Card.Title>
      <Typeahead
        clearButton
        id="sku-lookup-dropdown"
        labelKey={(option) => `${option.name} (${option.sku})`}
        multiple={true}
        onChange={setSelectedSKUs}
        options={skus}
        filterBy={handleFilter}
        placeholder="Search by service name"
        selected={selectedSKUs}
      />

      {selectedSKUs.length > 0 && (
        <div className={css(styles.bump)}>
          <SkuTable skus={selectedSKUs} />
        </div>
      )}
    </>
  );
};

SkuLookup.propTypes = {
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

const styles = StyleSheet.create({
  bump: {
    'margin-top': '2em',
  },
});

export default SkuLookup;
