import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';

import { Alert, Button } from '@threeplayground/index';

import PricingBlocksTable from './PricingBlocksTable';
import AddBlockModal from './AddBlockModal';
import BulkEditModal from './BulkEditModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { Collapse } from 'react-bootstrap';

const PricingBlocksApp = ({
  authToken,
  resourceId,
  resourceName,
  resourceType,
  breadcrumbs,
  pricingBlocks,
  skus,
  loadError,
  downloadUrl,
  uploadUrl,
}) => {
  const [blocks, setBlocks] = useState(pricingBlocks);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [blockToDelete, setBlockToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [csvErrors, setCsvErrors] = useState([]);
  const [csvErrorsExpanded, setCsvErrorsExpanded] = useState(false);

  const setTemporaryError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(null), 6000);
  };

  const setTemporarySuccess = (successMessage) => {
    setSuccess(successMessage);
    setTimeout(() => setSuccess(null), 3000);
  };

  const handleAdd = async (newBlock) => {
    try {
      const response = await fetch('/pricing_blocks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authToken,
        },
        body: JSON.stringify({
          resource_type: resourceType,
          resource_id: resourceId,
          pricing_block: newBlock,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setBlocks([...blocks, ...data.blocks]);
        setShowAddModal(false);
        setTemporarySuccess('Pricing block(s) added successfully');
      } else {
        setTemporaryError('Cannot create pricing block: ' + data.errors.join(', '));
      }
    } catch (err) {
      setTemporaryError('Error: Cannot create pricing block. Please try again.');
    }
  };

  const handleBulkEdit = async (updates) => {
    try {
      const response = await fetch('/pricing_blocks/bulk_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authToken,
        },
        body: JSON.stringify({
          ids: selectedBlocks,
          pricing_block: updates,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setBlocks(
          blocks.map((block) => {
            const updatedBlock = data.blocks.find((b) => b.id === block.id);
            return updatedBlock || block;
          })
        );
        setShowBulkEditModal(false);
        setSelectedBlocks([]);
        setTemporarySuccess('Pricing blocks updated successfully');
      } else {
        setTemporaryError('Error: Cannot update pricing blocks: ' + data.errors.join(', '));
      }
    } catch (err) {
      setTemporaryError('Error: Cannot update pricing blocks. Please try again.');
    }
  };

  const handleDelete = async (blockId) => {
    try {
      const response = await fetch(`/pricing_blocks/${blockId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authToken,
        },
      });

      if (response.ok) {
        setBlocks(blocks.filter((block) => block.id !== blockId));
        setBlockToDelete(null);
        setShowDeleteModal(false);
        setTemporarySuccess('Pricing block deleted successfully');
        setSelectedBlocks([]);
      } else {
        setTemporaryError('Error: Cannot delete pricing block. Please try again.');
      }
    } catch (err) {
      setTemporaryError('Error: Failed to delete pricing block. Please try again.');
    }
  };

  const handleBulkDelete = async () => {
    try {
      const response = await fetch('/pricing_blocks/bulk_destroy', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authToken,
        },
        body: JSON.stringify({ ids: selectedBlocks }),
      });

      if (response.ok) {
        setBlocks(blocks.filter((block) => !selectedBlocks.includes(block.id)));
        setSelectedBlocks([]);
        setShowDeleteModal(false);
        setTemporarySuccess('Pricing blocks deleted successfully');
      } else {
        setTemporaryError('Error: Cannot delete pricing blocks. Please try again.');
      }
    } catch (err) {
      setTemporaryError('Error: Failed to delete pricing blocks. Please try again.');
    }
  };

  const handleDeleteClick = (blockId) => {
    setBlockToDelete(blockId);
    setShowDeleteModal(true);
  };

  const handleCsvUpload = async (uploadedFile) => {
    console.log('handleCsvUpload called with file:', uploadedFile?.name);
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('resource_type', resourceType);
    formData.append('resource_id', resourceId);
    formData.append('file', uploadedFile);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': authToken,
        },
      });

      const data = await response.json();

      if (Object.keys(data.errors || {}).length > 0) {
        handleCsvErrors(data);
        return;
      }

      setBlocks(data.pricingBlocks);
      setCsvErrors([]);
      setTemporarySuccess('CSV uploaded successfully');
    } catch (err) {
      setCsvErrors(['Failed to upload CSV']);
    }
  };

  const handleCsvErrors = (response) => {
    const newErrors = [];
    const errors = response.errors || {};

    for (const [key, value] of Object.entries(errors)) {
      if (key === 'columns') {
        value.forEach((error) => newErrors.push(error));
      } else if (key === 'resource') {
        value.forEach((error) => newErrors.push(error));
      } else {
        value.forEach((error) => newErrors.push(`Line ${key}: ${error}`));
      }
    }

    setCsvErrors(newErrors);
    setBlocks(response.pricingBlocks || []);
  };

  return (
    <>
      <div className={css(styles.toastContainer)}>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      </div>

      <div className={css(styles.alertContainer)}>
        {loadError && <Alert variant="danger">{loadError}</Alert>}
        {csvErrors.length > 0 && (
          <Alert variant="danger">
            <ul className={css(styles.errorList)}>
              {csvErrors.slice(0, 6).map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
            {csvErrors.length > 6 && (
              <>
                <Collapse in={csvErrorsExpanded}>
                  <ul className={css(styles.errorList)}>
                    {csvErrors.slice(6).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </Collapse>
                <Button
                  onClick={() => setCsvErrorsExpanded(!csvErrorsExpanded)}
                  variant="secondary"
                  className={css(styles.collapseBtn)}
                >
                  {csvErrorsExpanded ? 'View less' : `View ${csvErrors.length - 6} more`}
                </Button>
              </>
            )}
          </Alert>
        )}
      </div>
      {!loadError && (
        <>
          {breadcrumbs.length > 0 && (
            <Breadcrumb>
              {breadcrumbs.map((link, index) => (
                <Breadcrumb.Item key={index} href={link.href}>
                  {link.name}
                </Breadcrumb.Item>
              ))}
              <Breadcrumb.Item key={breadcrumbs.length} active href="#">
                {resourceType}
              </Breadcrumb.Item>
            </Breadcrumb>
          )}

          <div className={css(styles.flexContainer)}>
            <Card.Title className={css(styles.flexRow)}>
              Pricing Blocks for {resourceName} ({resourceType}: {resourceId})
            </Card.Title>
          </div>

          <Alert variant="info" className={css(styles.csvInfo)}>
            <p>You can manage pricing blocks in two ways:</p>
            <ol>
              <li>
                <strong>Manual Management:</strong>
                <ul>
                  <li>Add individual blocks using the Add Block button</li>
                  <li>Select multiple blocks to edit or delete them in bulk</li>
                </ul>
              </li>
              <li>
                <strong>CSV Upload</strong> (replaces all existing blocks)<strong>:</strong>
                <ul>
                  <li>Download the CSV template containing all existing pricing blocks</li>
                  <li>Modify the CSV to include ALL desired pricing blocks</li>
                  {/* Prefer the look of margined list items over nested */}
                  <li style={{ marginLeft: '2em' }}>The `Add Block` modal has a SKU look tool</li>
                  <li>Upload the modified CSV to replace all existing blocks</li>
                </ul>
              </li>
            </ol>
          </Alert>

          <PricingBlocksTable
            blocks={blocks}
            selectedBlocks={selectedBlocks}
            onSelectionChange={setSelectedBlocks}
            onDeleteClick={handleDeleteClick}
            onAddClick={() => setShowAddModal(true)}
            onBulkEditClick={() => setShowBulkEditModal(true)}
            onBulkDeleteClick={() => setShowDeleteModal(true)}
            onUpload={handleCsvUpload}
            downloadUrl={downloadUrl}
          />

          <AddBlockModal
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            onSubmit={handleAdd}
            skus={skus}
          />

          <BulkEditModal
            show={showBulkEditModal}
            onHide={() => setShowBulkEditModal(false)}
            onSubmit={handleBulkEdit}
            skus={skus}
            selectedCount={selectedBlocks.length}
          />

          <DeleteConfirmationModal
            show={showDeleteModal}
            onHide={() => {
              setShowDeleteModal(false);
              setBlockToDelete(null);
            }}
            onConfirm={blockToDelete ? () => handleDelete(blockToDelete) : handleBulkDelete}
            count={blockToDelete ? 1 : selectedBlocks.length}
          />
        </>
      )}
    </>
  );
};

PricingBlocksApp.propTypes = {
  authToken: PropTypes.string.isRequired,
  resourceId: PropTypes.number.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
  pricingBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      startMinute: PropTypes.number.isRequired,
      endMinute: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      isAutoCreated: PropTypes.bool,
    })
  ).isRequired,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  loadError: PropTypes.string,
  downloadUrl: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    marginBottom: '1em',
  },
  flexRow: {
    flex: 1,
  },
  skuLookup: {
    marginBottom: '5em',
  },
  errorList: {
    marginBottom: 0,
  },
  collapseBtn: {
    marginLeft: 60,
    marginTop: 10,
  },
  csvInfo: {
    marginBottom: '1rem',
  },
  toastContainer: {
    position: 'fixed',
    top: '80px',
    left: '20px',
    zIndex: 9999,
    minWidth: '300px',
    maxWidth: '500px',
    '> *': {
      backgroundColor: 'white',
      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    },
  },
});

export default PricingBlocksApp;
