// This file was generated by yarn graphql:codegen. Do not manually edit this
// file as changes will be lost on regeneration. If something seems incorrect
// the Framework team is happy to pair on adjusting configuration in codegen.ts.
/* eslint-disable */
import type { FetchErrors } from './_fetcher';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from './_fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
};

/** Attributes identifying an account to enable/disable advanced output formats */
export type AccountAdvancedOutputFormatsInput = {
  accountId: Scalars['ID'];
  enableFormat: Scalars['Boolean'];
};

export type AccountOutputFormat = {
  __typename?: 'AccountOutputFormat';
  accountId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  outputFormatId: Maybe<Scalars['ID']>;
};

/** Attributes identifying an account output format to create/destroy */
export type AccountOutputFormatInput = {
  accountId: Scalars['ID'];
  enableFormat: Scalars['Boolean'];
  existingAccountOutputFormatId: InputMaybe<Scalars['ID']>;
  outputFormatId: Scalars['ID'];
};

export type Applicant = {
  __typename?: 'Applicant';
  contractorReferral: Maybe<ContractorReferral>;
  email: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type ApplicantGraphql = {
  __typename?: 'ApplicantGraphql';
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type ApplicantWhereUniqueInput = {
  /** email */
  emailEq: InputMaybe<Scalars['String']>;
  /** ID */
  idEq: InputMaybe<Scalars['ID']>;
};

export type ApplicantsCollection = {
  __typename?: 'ApplicantsCollection';
  items: Array<Applicant>;
  pageInfo: Maybe<WillPaginateInfo>;
};

/** Autogenerated return type of BulkUpdateJobsMutation */
export type BulkUpdateJobsMutationPayload = {
  __typename?: 'BulkUpdateJobsMutationPayload';
  results: Array<BulkUpdateJobsResult>;
};

export type BulkUpdateJobsResult = {
  __typename?: 'BulkUpdateJobsResult';
  jobId: Scalars['Int'];
  reason: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Contractor = {
  __typename?: 'Contractor';
  availableJobAccessCount: Scalars['Int'];
  email: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

export type ContractorGroup = {
  __typename?: 'ContractorGroup';
  contractorCount: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

export type ContractorReferral = {
  __typename?: 'ContractorReferral';
  applicant: Maybe<Applicant>;
  completedAt: Maybe<Scalars['Int']>;
  contractor: Maybe<Contractor>;
  createdAt: Scalars['Int'];
  expiresAt: Maybe<Scalars['Int']>;
  hoursTarget: Maybe<Scalars['Float']>;
  payout: Maybe<Scalars['Float']>;
  referralCode: Maybe<ContractorReferralCode>;
  state: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['Int']>;
};

export type ContractorReferralCode = {
  __typename?: 'ContractorReferralCode';
  active: Scalars['Boolean'];
  contractor: Maybe<Contractor>;
  createdAt: Scalars['Int'];
  referralLimit: Maybe<Scalars['Int']>;
  referrals: Maybe<Array<ContractorReferral>>;
  token: Scalars['String'];
  updatedAt: Scalars['Int'];
};

export type ContractorReferralCodesCollection = {
  __typename?: 'ContractorReferralCodesCollection';
  items: Array<ContractorReferralCode>;
  pageInfo: Maybe<WillPaginateInfo>;
};

export type ContractorsCollection = {
  __typename?: 'ContractorsCollection';
  items: Array<Contractor>;
  pageInfo: Maybe<WillPaginateInfo>;
};

/** Autogenerated return type of CreateGdprDeletionRequestMutation */
export type CreateGdprDeletionRequestMutationPayload = {
  __typename?: 'CreateGdprDeletionRequestMutationPayload';
  data: Maybe<GdprDeletionRequestGraphql>;
  errors: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of CreateOpsTaskInvocationMutation */
export type CreateOpsTaskInvocationMutationPayload = {
  __typename?: 'CreateOpsTaskInvocationMutationPayload';
  data: Maybe<OpsTaskInvocationGraphql>;
  errors: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of CreateReferralCodesMutation */
export type CreateReferralCodesMutationPayload = {
  __typename?: 'CreateReferralCodesMutationPayload';
  results: Array<CreateReferralCodesResult>;
};

export type CreateReferralCodesResult = {
  __typename?: 'CreateReferralCodesResult';
  contractorId: Maybe<Scalars['Int']>;
  error: Maybe<Scalars['String']>;
  referralCode: Maybe<ContractorReferralCode>;
  success: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteMarketManagementControlMutation */
export type DeleteMarketManagementControlMutationPayload = {
  __typename?: 'DeleteMarketManagementControlMutationPayload';
  currentControl: Maybe<MarketManagementControl>;
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type GdprDeletionRequestCreateInput = {
  /** user email */
  email: Scalars['String'];
};

export type GdprDeletionRequestGraphql = {
  __typename?: 'GdprDeletionRequestGraphql';
  email: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  stateTransitions: GdprDeletionRequestStateTransitionConnectionGraphql;
};


export type GdprDeletionRequestGraphqlStateTransitionsArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  orderBy?: InputMaybe<Array<GdprDeletionRequestStateTransitionOrderByEnum>>;
  where?: InputMaybe<GdprDeletionRequestStateTransitionWhereInput>;
};

export type GdprDeletionRequestStateTransitionConnectionGraphql = {
  __typename?: 'GdprDeletionRequestStateTransitionConnectionGraphql';
  edges: Array<GdprDeletionRequestStateTransitionEdgeGraphql>;
  pageInfo: Maybe<PageInfo>;
};

export type GdprDeletionRequestStateTransitionEdgeGraphql = {
  __typename?: 'GdprDeletionRequestStateTransitionEdgeGraphql';
  node: GdprDeletionRequestStateTransitionGraphql;
};

export type GdprDeletionRequestStateTransitionGraphql = {
  __typename?: 'GdprDeletionRequestStateTransitionGraphql';
  createdAt: Scalars['ISO8601DateTime'];
  errorCode: Maybe<Scalars['String']>;
  event: Maybe<Scalars['String']>;
  from: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  namespace: Maybe<Scalars['String']>;
  to: Scalars['String'];
};

export enum GdprDeletionRequestStateTransitionOrderByEnum {
  /** Sort by 'Created At' ascending */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort by 'Created At' descending */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type GdprDeletionRequestStateTransitionWhereInput = {
  /** Event */
  eventEq: InputMaybe<Scalars['String']>;
  /** From */
  fromEq: InputMaybe<Scalars['String']>;
  /** To */
  toEq: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of HeartbeatMutation */
export type HeartbeatMutationPayload = {
  __typename?: 'HeartbeatMutationPayload';
  success: Scalars['Boolean'];
};

export type Job = {
  __typename?: 'Job';
  accessCount: Scalars['Int'];
  deadline: Maybe<Scalars['Int']>;
  duration: Maybe<Scalars['Int']>;
  forceList: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  jobTraits: Array<ResourceTrait>;
  loggedInAccessCount: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  payrate: Maybe<Scalars['Float']>;
  priorityStars: Maybe<Scalars['Int']>;
  project: Maybe<Project>;
  service: Maybe<Service>;
  state: Scalars['String'];
  timeOnMarket: Maybe<Scalars['Float']>;
  topOfQueue: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type JobType = {
  __typename?: 'JobType';
  fullName: Scalars['String'];
  id: Scalars['Int'];
  languageId: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type JobsCollection = {
  __typename?: 'JobsCollection';
  items: Array<Job>;
  pageInfo: Maybe<WillPaginateInfo>;
  totalDuration: Scalars['BigInt'];
};

export type MarketManagementControl = {
  __typename?: 'MarketManagementControl';
  contractorGroupId: Maybe<Scalars['Int']>;
  controlType: Scalars['String'];
  controlValue: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  inherited: Scalars['Boolean'];
  jobTypeId: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of MarketManagementControlMutation */
export type MarketManagementControlMutationPayload = {
  __typename?: 'MarketManagementControlMutationPayload';
  currentControl: Maybe<MarketManagementControl>;
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkUpdateJobs: Maybe<BulkUpdateJobsMutationPayload>;
  /** BEST_PRACTICE_V1: uses Secure GraphQL helper classes. */
  createGdprDeletionRequest: Maybe<CreateGdprDeletionRequestMutationPayload>;
  /** BEST_PRACTICE_V1: uses Secure GraphQL helper classes. */
  createOpsTaskInvocation: Maybe<CreateOpsTaskInvocationMutationPayload>;
  createReferralCodes: Maybe<CreateReferralCodesMutationPayload>;
  deleteMarketManagementControl: Maybe<DeleteMarketManagementControlMutationPayload>;
  heartbeat: Maybe<HeartbeatMutationPayload>;
  marketManagementControl: Maybe<MarketManagementControlMutationPayload>;
  resaltSubmarkets: Maybe<ResaltSubmarketsMutationPayload>;
  setAccountOutputFormatEnabled: Maybe<SetAccountOutputFormatEnabledMutationPayload>;
  setAdvancedOutputFormats: Maybe<SetAdvancedOutputFormatsMutationPayload>;
  submarketRules: Maybe<SubmarketRulesMutationPayload>;
};


export type MutationBulkUpdateJobsArgs = {
  addTraitIds: InputMaybe<Array<Scalars['Int']>>;
  forceList: InputMaybe<Scalars['Boolean']>;
  jobIds: Array<Scalars['Int']>;
  lockJobs: InputMaybe<Scalars['Boolean']>;
  removeTraitIds: InputMaybe<Array<Scalars['Int']>>;
  resetTraits: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateGdprDeletionRequestArgs = {
  data: GdprDeletionRequestCreateInput;
};


export type MutationCreateOpsTaskInvocationArgs = {
  data: OpsTaskInvocationCreateInput;
};


export type MutationCreateReferralCodesArgs = {
  contractorIds: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationDeleteMarketManagementControlArgs = {
  id: Scalars['Int'];
};


export type MutationMarketManagementControlArgs = {
  contractorGroupId: InputMaybe<Scalars['Int']>;
  controlType: Scalars['String'];
  controlValue: Scalars['String'];
  jobTypeId: InputMaybe<Scalars['Int']>;
};


export type MutationSetAccountOutputFormatEnabledArgs = {
  accountOutputFormat: AccountOutputFormatInput;
};


export type MutationSetAdvancedOutputFormatsArgs = {
  input: AccountAdvancedOutputFormatsInput;
};


export type MutationSubmarketRulesArgs = {
  rules: Array<SubmarketRuleInput>;
};

export type MutationError = {
  __typename?: 'MutationError';
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
  path: Scalars['String'];
};

export type OpsTaskConnectionGraphql = {
  __typename?: 'OpsTaskConnectionGraphql';
  edges: Array<OpsTaskEdgeGraphql>;
  pageInfo: Maybe<PageInfo>;
};

export type OpsTaskEdgeGraphql = {
  __typename?: 'OpsTaskEdgeGraphql';
  node: OpsTaskGraphql;
};

export type OpsTaskGraphql = {
  __typename?: 'OpsTaskGraphql';
  active: Scalars['Boolean'];
  argsSpec: Maybe<Scalars['String']>;
  callCount: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  notes: Maybe<Scalars['String']>;
  template: Maybe<Scalars['String']>;
  typicalRunTimeInMinutes: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type OpsTaskInvocationConnectionGraphql = {
  __typename?: 'OpsTaskInvocationConnectionGraphql';
  edges: Array<OpsTaskInvocationEdgeGraphql>;
  pageInfo: Maybe<PageInfo>;
};

export type OpsTaskInvocationCreateInput = {
  /** Args JSON */
  args: InputMaybe<Scalars['String']>;
  /** Ops Task Id */
  opsTaskId: Scalars['Int'];
  /** Run Notes */
  runNotes: InputMaybe<Scalars['String']>;
};

export type OpsTaskInvocationEdgeGraphql = {
  __typename?: 'OpsTaskInvocationEdgeGraphql';
  node: OpsTaskInvocationGraphql;
};

export type OpsTaskInvocationGraphql = {
  __typename?: 'OpsTaskInvocationGraphql';
  args: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  errorNotes: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invocationMethod: Scalars['String'];
  opsTask: Maybe<OpsTaskGraphql>;
  runNotes: Maybe<Scalars['String']>;
  state: Scalars['String'];
  success: Maybe<Scalars['Boolean']>;
  threeplayResqueJob: Maybe<ThreeplayResqueJobGraphql>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum OpsTaskInvocationOrderByEnum {
  /** Sort by 'Created At' ascending */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort by 'Created At' descending */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type OpsTaskInvocationWhereInput = {
  /** Created At Greater Than */
  createdAtGt: InputMaybe<Scalars['ISO8601DateTime']>;
};

export enum OpsTaskOrderByEnum {
  /** Sort by 'Created At' ascending */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort by 'Created At' descending */
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type OpsTaskWhereInput = {
  /** display name or description contains */
  search: InputMaybe<Scalars['String']>;
};

export type OpsTaskWhereUniqueInput = {
  /** ID */
  idEq: InputMaybe<Scalars['ID']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  resultCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accountAdvancedFormatsEnabled: Scalars['Boolean'];
  accountOutputFormats: Maybe<Array<AccountOutputFormat>>;
  applicant: Maybe<ApplicantGraphql>;
  applicants: ApplicantsCollection;
  contractorGroups: Array<ContractorGroup>;
  contractorReferralCodes: Maybe<ContractorReferralCodesCollection>;
  contractors: ContractorsCollection;
  jobTypes: Array<JobType>;
  jobs: JobsCollection;
  marketManagementControlMatrix: Array<MarketManagementControl>;
  marketManagementControls: Array<MarketManagementControl>;
  opsTask: Maybe<OpsTaskGraphql>;
  opsTaskInvocations: OpsTaskInvocationConnectionGraphql;
  opsTasks: OpsTaskConnectionGraphql;
  resqueJobs: Array<ResqueJob>;
  submarketRules: Array<SubmarketRule>;
  submarkets: Array<Submarket>;
  ummV1Controls: UmmV1Controls;
  user: Maybe<User>;
  variants: Array<VariantGraphql>;
};


export type QueryAccountAdvancedFormatsEnabledArgs = {
  accountId: Scalars['Int'];
};


export type QueryAccountOutputFormatsArgs = {
  accountId: Scalars['Int'];
};


export type QueryApplicantArgs = {
  where: ApplicantWhereUniqueInput;
};


export type QueryApplicantsArgs = {
  isReferredByContractor: InputMaybe<Scalars['Boolean']>;
};


export type QueryContractorReferralCodesArgs = {
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
};


export type QueryContractorsArgs = {
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type QueryJobsArgs = {
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type QueryMarketManagementControlMatrixArgs = {
  contractorGroupIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  controlTypes: InputMaybe<Array<Scalars['String']>>;
  jobTypeIds: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryOpsTaskArgs = {
  where: OpsTaskWhereUniqueInput;
};


export type QueryOpsTaskInvocationsArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OpsTaskInvocationOrderByEnum>>;
  where?: InputMaybe<OpsTaskInvocationWhereInput>;
};


export type QueryOpsTasksArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OpsTaskOrderByEnum>>;
  where?: InputMaybe<OpsTaskWhereInput>;
};


export type QueryResqueJobsArgs = {
  name: Scalars['String'];
};


export type QueryUmmV1ControlsArgs = {
  contractorGroupId: InputMaybe<Scalars['Int']>;
  jobTypeId: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryVariantsArgs = {
  name: VariantsEnum;
};

/** Autogenerated return type of ResaltSubmarketsMutation */
export type ResaltSubmarketsMutationPayload = {
  __typename?: 'ResaltSubmarketsMutationPayload';
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ResourceTrait = {
  __typename?: 'ResourceTrait';
  hierarchyCopy: Scalars['Boolean'];
  id: Scalars['Int'];
  resourceId: Scalars['Int'];
  resourceType: Scalars['String'];
  trait: Maybe<Trait>;
  traitId: Scalars['Int'];
  traited: Scalars['Boolean'];
};

export type ResqueJob = {
  __typename?: 'ResqueJob';
  args: Maybe<Scalars['String']>;
  index: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  processing: Scalars['Boolean'];
  queue: Maybe<Scalars['String']>;
  runAt: Maybe<Scalars['Int']>;
};

export type Service = {
  __typename?: 'Service';
  deadline: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  opsDeadline: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

/** Autogenerated return type of SetAccountOutputFormatEnabledMutation */
export type SetAccountOutputFormatEnabledMutationPayload = {
  __typename?: 'SetAccountOutputFormatEnabledMutationPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SetAdvancedOutputFormatsMutation */
export type SetAdvancedOutputFormatsMutationPayload = {
  __typename?: 'SetAdvancedOutputFormatsMutationPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Submarket = {
  __typename?: 'Submarket';
  availableJobCount: Scalars['Int'];
  contractorCount: Scalars['Int'];
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

export type SubmarketAllocation = {
  __typename?: 'SubmarketAllocation';
  allocation: Scalars['Float'];
  endBucket: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  startBucket: Maybe<Scalars['Int']>;
  submarket: Submarket;
  submarketId: Scalars['Int'];
  submarketRuleId: Scalars['Int'];
};

export type SubmarketAllocationInput = {
  /** Fraction between 0-1 to allocate to this submarket */
  allocation: Scalars['Float'];
  /** Submarket ID */
  submarketId: Scalars['Int'];
};

export type SubmarketRule = {
  __typename?: 'SubmarketRule';
  capturing: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
  fallback: Scalars['Boolean'];
  filter: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  submarketAllocations: Array<SubmarketAllocation>;
};

/** Submarket rule and allocations */
export type SubmarketRuleInput = {
  /** Rule description */
  description: InputMaybe<Scalars['String']>;
  /** Entity type: Job or Contractor */
  entityType: Scalars['String'];
  /** Ransack query for the entities */
  filter: InputMaybe<Scalars['String']>;
  /** ID of existing Submarket Rule */
  id: InputMaybe<Scalars['Int']>;
  /** Delete this rule */
  markedForDeletion: InputMaybe<Scalars['Boolean']>;
  /** Name of the rule */
  name: InputMaybe<Scalars['String']>;
  submarketAllocations: Array<SubmarketAllocationInput>;
};

/** Autogenerated return type of SubmarketRulesMutation */
export type SubmarketRulesMutationPayload = {
  __typename?: 'SubmarketRulesMutationPayload';
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ThreeplayResqueJobGraphql = {
  __typename?: 'ThreeplayResqueJobGraphql';
  args: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  endedAt: Maybe<Scalars['ISO8601DateTime']>;
  enqueuedAt: Maybe<Scalars['ISO8601DateTime']>;
  errorLog: Maybe<Scalars['String']>;
  exceptionLogId: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  queue: Maybe<Scalars['String']>;
  startedAt: Maybe<Scalars['ISO8601DateTime']>;
  success: Maybe<Scalars['Boolean']>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  worker: Maybe<Scalars['String']>;
};

export type Trait = {
  __typename?: 'Trait';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UmmV1Controls = {
  __typename?: 'UmmV1Controls';
  items: Array<MarketManagementControl>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastLoginAt: Maybe<Scalars['ISO8601DateTime']>;
  lastname: Scalars['String'];
  type: Maybe<Scalars['String']>;
};

export type UserWhereUniqueInput = {
  /** user email equals */
  emailEq: InputMaybe<Scalars['String']>;
  /** user id equals */
  idEq: InputMaybe<Scalars['Int']>;
};

export type VariantGraphql = {
  __typename?: 'VariantGraphql';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  description: Scalars['String'];
  displayName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum VariantsEnum {
  /** Broadcast Scripting Service Formats */
  BroadcastScriptingFormat = 'BROADCAST_SCRIPTING_FORMAT',
  /** Caption Styling Service Levels */
  CaptionStylingLevel = 'CAPTION_STYLING_LEVEL',
  /** Market job types */
  JobType = 'JOB_TYPE',
  /** Market traits */
  Trait = 'TRAIT'
}

export type WillPaginateInfo = {
  __typename?: 'WillPaginateInfo';
  currentPage: Scalars['Int'];
  nextPage: Scalars['Int'];
  perPage: Scalars['Int'];
  previousPage: Scalars['Int'];
  totalEntries: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type VariantListQueryVariables = Exact<{
  name: VariantsEnum;
}>;


export type VariantListQuery = { __typename?: 'Query', variants: Array<{ __typename?: 'VariantGraphql', id: string, displayName: string | null }> };

export type ContractorStatsQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']>;
}>;


export type ContractorStatsQuery = { __typename?: 'Query', contractors: { __typename?: 'ContractorsCollection', items: Array<{ __typename?: 'Contractor', id: number, name: string | null, availableJobAccessCount: number }> } };

export type JobStatsQueryVariables = Exact<{
  query: InputMaybe<Scalars['String']>;
}>;


export type JobStatsQuery = { __typename?: 'Query', jobs: { __typename?: 'JobsCollection', totalDuration: string, items: Array<{ __typename?: 'Job', id: number, type: string, name: string | null, duration: number | null, deadline: number | null, accessCount: number, forceList: boolean | null, loggedInAccessCount: number, priorityStars: number | null, topOfQueue: boolean | null, timeOnMarket: number | null, project: { __typename?: 'Project', id: number, name: string } | null, service: { __typename?: 'Service', id: number, type: string, deadline: number | null, opsDeadline: number | null } | null }> } };

export type SubmarketRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type SubmarketRulesQuery = { __typename?: 'Query', submarkets: Array<{ __typename?: 'Submarket', id: number, name: string | null, contractorCount: number, availableJobCount: number }>, submarketRules: Array<{ __typename?: 'SubmarketRule', id: number, entityType: string, filter: string | null, fallback: boolean, name: string | null, description: string | null, submarketAllocations: Array<{ __typename?: 'SubmarketAllocation', submarketId: number, allocation: number }> }> };

export type UmmV1ControlsQueryVariables = Exact<{
  contractorGroupId: InputMaybe<Scalars['Int']>;
  jobTypeId: InputMaybe<Scalars['Int']>;
}>;


export type UmmV1ControlsQuery = { __typename?: 'Query', ummV1Controls: { __typename?: 'UmmV1Controls', items: Array<{ __typename?: 'MarketManagementControl', id: number | null, contractorGroupId: number | null, jobTypeId: number | null, controlType: string, controlValue: string | null }> } };

export type MarketManagementControlMatrixQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketManagementControlMatrixQuery = { __typename?: 'Query', marketManagementControlMatrix: Array<{ __typename?: 'MarketManagementControl', id: number | null, controlType: string, controlValue: string | null, jobTypeId: number | null, contractorGroupId: number | null, inherited: boolean }> };


export const VariantListDocument = `
    query VariantList($name: VariantsEnum!) {
  variants(name: $name) {
    id
    displayName
  }
}
    `;
export const useVariantListQuery = <
      TData = VariantListQuery,
      TError = FetchErrors
    >(
      variables: VariantListQueryVariables,
      options?: UseQueryOptions<VariantListQuery, TError, TData>
    ) =>
    useQuery<VariantListQuery, TError, TData>(
      ['VariantList', variables],
      fetcher<VariantListQuery, VariantListQueryVariables>(VariantListDocument, variables),
      options
    );

useVariantListQuery.getKey = (variables: VariantListQueryVariables) => ['VariantList', variables];
;

export const useInfiniteVariantListQuery = <
      TData = VariantListQuery,
      TError = FetchErrors
    >(
      variables: VariantListQueryVariables,
      options?: UseInfiniteQueryOptions<VariantListQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<VariantListQuery, TError, TData>(
      ['VariantList.infinite', variables],
      (metaData) => fetcher<VariantListQuery, VariantListQueryVariables>(VariantListDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteVariantListQuery.getKey = (variables: VariantListQueryVariables) => ['VariantList.infinite', variables];
;

useVariantListQuery.fetcher = (variables: VariantListQueryVariables, options?: RequestInit['headers']) => fetcher<VariantListQuery, VariantListQueryVariables>(VariantListDocument, variables, options);
export const ContractorStatsQueryDocument = `
    query ContractorStatsQuery($query: String) {
  contractors(query: $query) {
    items {
      id
      name
      availableJobAccessCount
    }
  }
}
    `;
export const useContractorStatsQuery = <
      TData = ContractorStatsQuery,
      TError = FetchErrors
    >(
      variables?: ContractorStatsQueryVariables,
      options?: UseQueryOptions<ContractorStatsQuery, TError, TData>
    ) =>
    useQuery<ContractorStatsQuery, TError, TData>(
      variables === undefined ? ['ContractorStatsQuery'] : ['ContractorStatsQuery', variables],
      fetcher<ContractorStatsQuery, ContractorStatsQueryVariables>(ContractorStatsQueryDocument, variables),
      options
    );

useContractorStatsQuery.getKey = (variables?: ContractorStatsQueryVariables) => variables === undefined ? ['ContractorStatsQuery'] : ['ContractorStatsQuery', variables];
;

export const useInfiniteContractorStatsQuery = <
      TData = ContractorStatsQuery,
      TError = FetchErrors
    >(
      variables?: ContractorStatsQueryVariables,
      options?: UseInfiniteQueryOptions<ContractorStatsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ContractorStatsQuery, TError, TData>(
      variables === undefined ? ['ContractorStatsQuery.infinite'] : ['ContractorStatsQuery.infinite', variables],
      (metaData) => fetcher<ContractorStatsQuery, ContractorStatsQueryVariables>(ContractorStatsQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteContractorStatsQuery.getKey = (variables?: ContractorStatsQueryVariables) => variables === undefined ? ['ContractorStatsQuery.infinite'] : ['ContractorStatsQuery.infinite', variables];
;

useContractorStatsQuery.fetcher = (variables?: ContractorStatsQueryVariables, options?: RequestInit['headers']) => fetcher<ContractorStatsQuery, ContractorStatsQueryVariables>(ContractorStatsQueryDocument, variables, options);
export const JobStatsDocument = `
    query JobStats($query: String) {
  jobs(query: $query) {
    items {
      id
      type
      name
      duration
      deadline
      accessCount
      forceList
      loggedInAccessCount
      priorityStars
      project {
        id
        name
      }
      topOfQueue
      service {
        id
        type
        deadline
        opsDeadline
      }
      timeOnMarket
    }
    totalDuration
  }
}
    `;
export const useJobStatsQuery = <
      TData = JobStatsQuery,
      TError = FetchErrors
    >(
      variables?: JobStatsQueryVariables,
      options?: UseQueryOptions<JobStatsQuery, TError, TData>
    ) =>
    useQuery<JobStatsQuery, TError, TData>(
      variables === undefined ? ['JobStats'] : ['JobStats', variables],
      fetcher<JobStatsQuery, JobStatsQueryVariables>(JobStatsDocument, variables),
      options
    );

useJobStatsQuery.getKey = (variables?: JobStatsQueryVariables) => variables === undefined ? ['JobStats'] : ['JobStats', variables];
;

export const useInfiniteJobStatsQuery = <
      TData = JobStatsQuery,
      TError = FetchErrors
    >(
      variables?: JobStatsQueryVariables,
      options?: UseInfiniteQueryOptions<JobStatsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<JobStatsQuery, TError, TData>(
      variables === undefined ? ['JobStats.infinite'] : ['JobStats.infinite', variables],
      (metaData) => fetcher<JobStatsQuery, JobStatsQueryVariables>(JobStatsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteJobStatsQuery.getKey = (variables?: JobStatsQueryVariables) => variables === undefined ? ['JobStats.infinite'] : ['JobStats.infinite', variables];
;

useJobStatsQuery.fetcher = (variables?: JobStatsQueryVariables, options?: RequestInit['headers']) => fetcher<JobStatsQuery, JobStatsQueryVariables>(JobStatsDocument, variables, options);
export const SubmarketRulesDocument = `
    query submarketRules {
  submarkets {
    id
    name
    contractorCount
    availableJobCount
  }
  submarketRules {
    id
    entityType
    filter
    fallback
    name
    description
    submarketAllocations {
      submarketId
      allocation
    }
  }
}
    `;
export const useSubmarketRulesQuery = <
      TData = SubmarketRulesQuery,
      TError = FetchErrors
    >(
      variables?: SubmarketRulesQueryVariables,
      options?: UseQueryOptions<SubmarketRulesQuery, TError, TData>
    ) =>
    useQuery<SubmarketRulesQuery, TError, TData>(
      variables === undefined ? ['submarketRules'] : ['submarketRules', variables],
      fetcher<SubmarketRulesQuery, SubmarketRulesQueryVariables>(SubmarketRulesDocument, variables),
      options
    );

useSubmarketRulesQuery.getKey = (variables?: SubmarketRulesQueryVariables) => variables === undefined ? ['submarketRules'] : ['submarketRules', variables];
;

export const useInfiniteSubmarketRulesQuery = <
      TData = SubmarketRulesQuery,
      TError = FetchErrors
    >(
      variables?: SubmarketRulesQueryVariables,
      options?: UseInfiniteQueryOptions<SubmarketRulesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SubmarketRulesQuery, TError, TData>(
      variables === undefined ? ['submarketRules.infinite'] : ['submarketRules.infinite', variables],
      (metaData) => fetcher<SubmarketRulesQuery, SubmarketRulesQueryVariables>(SubmarketRulesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSubmarketRulesQuery.getKey = (variables?: SubmarketRulesQueryVariables) => variables === undefined ? ['submarketRules.infinite'] : ['submarketRules.infinite', variables];
;

useSubmarketRulesQuery.fetcher = (variables?: SubmarketRulesQueryVariables, options?: RequestInit['headers']) => fetcher<SubmarketRulesQuery, SubmarketRulesQueryVariables>(SubmarketRulesDocument, variables, options);
export const UmmV1ControlsQueryDocument = `
    query UmmV1ControlsQuery($contractorGroupId: Int, $jobTypeId: Int) {
  ummV1Controls(contractorGroupId: $contractorGroupId, jobTypeId: $jobTypeId) {
    items {
      id
      contractorGroupId
      jobTypeId
      controlType
      controlValue
    }
  }
}
    `;
export const useUmmV1ControlsQuery = <
      TData = UmmV1ControlsQuery,
      TError = FetchErrors
    >(
      variables?: UmmV1ControlsQueryVariables,
      options?: UseQueryOptions<UmmV1ControlsQuery, TError, TData>
    ) =>
    useQuery<UmmV1ControlsQuery, TError, TData>(
      variables === undefined ? ['UmmV1ControlsQuery'] : ['UmmV1ControlsQuery', variables],
      fetcher<UmmV1ControlsQuery, UmmV1ControlsQueryVariables>(UmmV1ControlsQueryDocument, variables),
      options
    );

useUmmV1ControlsQuery.getKey = (variables?: UmmV1ControlsQueryVariables) => variables === undefined ? ['UmmV1ControlsQuery'] : ['UmmV1ControlsQuery', variables];
;

export const useInfiniteUmmV1ControlsQuery = <
      TData = UmmV1ControlsQuery,
      TError = FetchErrors
    >(
      variables?: UmmV1ControlsQueryVariables,
      options?: UseInfiniteQueryOptions<UmmV1ControlsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<UmmV1ControlsQuery, TError, TData>(
      variables === undefined ? ['UmmV1ControlsQuery.infinite'] : ['UmmV1ControlsQuery.infinite', variables],
      (metaData) => fetcher<UmmV1ControlsQuery, UmmV1ControlsQueryVariables>(UmmV1ControlsQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteUmmV1ControlsQuery.getKey = (variables?: UmmV1ControlsQueryVariables) => variables === undefined ? ['UmmV1ControlsQuery.infinite'] : ['UmmV1ControlsQuery.infinite', variables];
;

useUmmV1ControlsQuery.fetcher = (variables?: UmmV1ControlsQueryVariables, options?: RequestInit['headers']) => fetcher<UmmV1ControlsQuery, UmmV1ControlsQueryVariables>(UmmV1ControlsQueryDocument, variables, options);
export const MarketManagementControlMatrixQueryDocument = `
    query MarketManagementControlMatrixQuery {
  marketManagementControlMatrix(
    controlTypes: ["total_hour_target", "job_count_range", "project_cap", "deadline_distribution", "job_order_randomization", "priority_projects", "contractor_preference"]
  ) {
    id
    controlType
    controlValue
    jobTypeId
    contractorGroupId
    inherited
  }
}
    `;
export const useMarketManagementControlMatrixQuery = <
      TData = MarketManagementControlMatrixQuery,
      TError = FetchErrors
    >(
      variables?: MarketManagementControlMatrixQueryVariables,
      options?: UseQueryOptions<MarketManagementControlMatrixQuery, TError, TData>
    ) =>
    useQuery<MarketManagementControlMatrixQuery, TError, TData>(
      variables === undefined ? ['MarketManagementControlMatrixQuery'] : ['MarketManagementControlMatrixQuery', variables],
      fetcher<MarketManagementControlMatrixQuery, MarketManagementControlMatrixQueryVariables>(MarketManagementControlMatrixQueryDocument, variables),
      options
    );

useMarketManagementControlMatrixQuery.getKey = (variables?: MarketManagementControlMatrixQueryVariables) => variables === undefined ? ['MarketManagementControlMatrixQuery'] : ['MarketManagementControlMatrixQuery', variables];
;

export const useInfiniteMarketManagementControlMatrixQuery = <
      TData = MarketManagementControlMatrixQuery,
      TError = FetchErrors
    >(
      variables?: MarketManagementControlMatrixQueryVariables,
      options?: UseInfiniteQueryOptions<MarketManagementControlMatrixQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MarketManagementControlMatrixQuery, TError, TData>(
      variables === undefined ? ['MarketManagementControlMatrixQuery.infinite'] : ['MarketManagementControlMatrixQuery.infinite', variables],
      (metaData) => fetcher<MarketManagementControlMatrixQuery, MarketManagementControlMatrixQueryVariables>(MarketManagementControlMatrixQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMarketManagementControlMatrixQuery.getKey = (variables?: MarketManagementControlMatrixQueryVariables) => variables === undefined ? ['MarketManagementControlMatrixQuery.infinite'] : ['MarketManagementControlMatrixQuery.infinite', variables];
;

useMarketManagementControlMatrixQuery.fetcher = (variables?: MarketManagementControlMatrixQueryVariables, options?: RequestInit['headers']) => fetcher<MarketManagementControlMatrixQuery, MarketManagementControlMatrixQueryVariables>(MarketManagementControlMatrixQueryDocument, variables, options);