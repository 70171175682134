import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { isEqual } from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import { FlashMessageContainer } from './FlashMessageContainer';
import LiveEventGeneralInfo from './LiveEventGeneralInfo';
import CaptioningInterface from './CaptioningInterface';
import KeyboardShortcuts from './KeyboardShortcuts';
import MenuBar from './MenuBar';
import PunctuationCommands from './PunctuationCommands';
import { StatusIndicator } from './StatusIndicator';
import SpeakerLabels from './SpeakerLabels';
import Wordlist from './Wordlist';

import { confirmLeavePage } from './utils/domEventUtil';
import { keyboardShortcutsReducer } from './utils/keyboardShortcutsUtil';
import { initializeCasedNames } from './utils/caseFormattingUtil';
import { getLocalStorageData, updateLocalStorage } from './utils/localStorageUtil';

import moment from 'moment';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

import { userLogger } from '~/logic/UserLogger';
import { Logger } from '~/logic/Logger/Logger';
import { SwateiProvider } from './context/Swatei.Context';
import { FlashMessageProvider } from './context/FlashMessageContext';
import useInterval from '~/core/hooks/useInterval';

const AUDIO_ACCESS_POLLING_INTERVAL_MS = 15000;

function LiveEvent({
  apiPaths,
  audioAccess,
  captionFormatConfig,
  dataToCaption,
  eventStreamStartDateTime,
  generalInfo,
  msCognitiveServiceKey,
  msCognitiveServiceEndpointId,
  shouldRecordCaptionerAudio,
  speechmaticsConfig,
  userCheckedIn,
  zeroOffsetDateTime,
}) {
  const [connected, setConnected] = useState(false);
  const [speechEngineConnected, setSpeechEngineConnected] = useState(false);
  const [hasUserCheckedIn, setHasUserCheckedIn] = useState(userCheckedIn);
  const [captionerAudioAccess, setCaptionerAudioAccess] = useState(audioAccess);

  const persistedData = getLocalStorageData(generalInfo.swateiJobId);
  const persistedKeyboardShortcuts = persistedData?.keyboardShortcuts || [];
  const [keyboardShortcuts, keyboardShortcutsDispatcher] = useReducer(
    keyboardShortcutsReducer,
    persistedKeyboardShortcuts
  );

  const persistedSpeakerNames = persistedData?.speakerNames;
  const [speakerNames, setSpeakerNames] = useState(persistedSpeakerNames || getSpeakerNames());

  const indicator = (
    <StatusIndicator outputConnected={connected} speechEngineConnected={speechEngineConnected} />
  );
  const scheduledStartDateTime = new Date(generalInfo.scheduledStartTime * 1000);
  const zeroOffsetDateObject = new Date(zeroOffsetDateTime);

  useEffect(() => {
    confirmLeavePage();
    initializeCasedNames();
  }, []);

  function getSpeakerNames() {
    const eventSpeakers = generalInfo.speakerNames || [];
    const speakers = ['>>', ...eventSpeakers];
    return speakers;
  }

  useEffect(() => {
    if (hasUserCheckedIn) {
      logSwateiAmplitudeEvent('User Check-in');
    }
  }, [hasUserCheckedIn]);

  useEffect(() => {
    if (JSON.stringify(persistedKeyboardShortcuts) !== JSON.stringify(keyboardShortcuts)) {
      updateLocalStorage(generalInfo.swateiJobId, 'keyboardShortcuts', keyboardShortcuts);
    }
  }, [keyboardShortcuts]);

  useEffect(() => {
    if (JSON.stringify(persistedSpeakerNames) !== JSON.stringify(speakerNames)) {
      updateLocalStorage(generalInfo.swateiJobId, 'speakerNames', speakerNames);
    }
  }, [speakerNames]);

  // Only the EMBED workflow currently requires polling for audio access
  useInterval(
    fetchCaptionerAudioAccess,
    captionerAudioAccess?.type === 'EMBED' ? AUDIO_ACCESS_POLLING_INTERVAL_MS : null
  );

  async function fetchCaptionerAudioAccess() {
    try {
      const response = await fetch(apiPaths.audioAccess, {
        headers: { Accept: 'application/json' },
      });
      const data = await response.json();

      if (!isEqual(data, captionerAudioAccess)) {
        setCaptionerAudioAccess(data);
        Logger.getInstance().info({ message: 'Audio access information set' });
      }
    } catch (error) {
      Logger.getInstance().error({
        message: 'Failed to fetch audio access information',
        info: error,
      });
    }
  }

  function logSwateiAmplitudeEvent(eventName, additionalInfo = {}) {
    if (!dataToCaption.isPracticeMode) {
      const modeLabel = dataToCaption.isLiveEvent ? 'SWATEI' : 'SWATEIXapp';
      userLogger.logEvent(modeLabel, eventName, {
        ...eventInfo(),
        ...additionalInfo,
      });
    }
  }

  function eventInfo() {
    return {
      'Live Event Id': generalInfo.eventId,
      Time: moment().format(DATE_TIME_FORMAT),
    };
  }

  return (
    <>
      {!hasUserCheckedIn && (
        <Modal show={!hasUserCheckedIn} data-testid="beginSessionModal">
          <Modal.Header>
            <Modal.Title>Check in to Live Captioning Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your live captioning session starts on
              <strong>
                {' '}
                {scheduledStartDateTime.toDateString()} at {scheduledStartDateTime.toTimeString()}.
              </strong>
            </p>
            <p>
              <strong>
                Please review the rules below to make sure your session goes smoothly.
              </strong>
            </p>
            <ol>
              <li>
                By checking in, you are confirming that you can commit to the entire live captioning
                session. Leaving the session early or stopping captions may result in penalties and
                negative marks to your 3Play Media account.
              </li>
              <li>
                If any technical or personal emergencies arise, click the &quot;Help&quot; button in
                the upper right hand corner of your screen to report a problem to the 3Play Media
                admin team.
              </li>
            </ol>
            <Button onClick={() => setHasUserCheckedIn(true)}>Ok, Let&apos;s Go!</Button>
          </Modal.Body>
        </Modal>
      )}

      {hasUserCheckedIn && (
        <FlashMessageProvider>
          <SwateiProvider dataToCaption={dataToCaption} generalInfo={generalInfo}>
            <FlashMessageContainer />
            <Container fluid className={css(styles.mainContainer)} data-testid="swatei">
              <Row>
                <Col xs={12} md={4}>
                  <ErrorBoundary component="KeyboardShortcuts">
                    <KeyboardShortcuts
                      keyboardShortcuts={keyboardShortcuts}
                      keyboardShortcutsDispatcher={keyboardShortcutsDispatcher}
                    />
                  </ErrorBoundary>
                  <ErrorBoundary component="SpeakerLabels">
                    <SpeakerLabels speakerNames={speakerNames} setSpeakerNames={setSpeakerNames} />
                  </ErrorBoundary>
                  <ErrorBoundary component="PunctuationCommands">
                    <PunctuationCommands />
                  </ErrorBoundary>
                </Col>

                <Col xs={12} md={8}>
                  <ErrorBoundary component="MenuBar">
                    <MenuBar statusIndicator={indicator} jobId={generalInfo.swateiJobId} />
                  </ErrorBoundary>
                  <Row>
                    <Col>
                      <ErrorBoundary component="CaptioningInterface">
                        <CaptioningInterface
                          {...dataToCaption}
                          apiPaths={apiPaths}
                          audioAccess={captionerAudioAccess}
                          startSendingCaptionsTime={zeroOffsetDateObject}
                          eventAccessInfo={generalInfo.eventAccessInfo}
                          keyboardShortcuts={keyboardShortcuts}
                          logSwateiAmplitudeEvent={logSwateiAmplitudeEvent}
                          msCognitiveServiceKey={msCognitiveServiceKey}
                          msCognitiveServiceEndpointId={msCognitiveServiceEndpointId}
                          scheduledStartTime={generalInfo.scheduledStartTime}
                          setConnected={setConnected}
                          shouldRecordCaptionerAudio={shouldRecordCaptionerAudio}
                          speechEngineConnected={speechEngineConnected}
                          setSpeechEngineConnected={setSpeechEngineConnected}
                          speakerNames={speakerNames}
                          swateiJobId={generalInfo.swateiJobId}
                          speechmaticsConfig={speechmaticsConfig}
                          wordlist={generalInfo.wordlist || []}
                          captionFormatConfig={captionFormatConfig}
                          zeroOffsetDateObject={zeroOffsetDateObject}
                        />
                      </ErrorBoundary>
                    </Col>
                    <Col xs={12} xl>
                      <ErrorBoundary component="LiveEventGeneralInfo">
                        <LiveEventGeneralInfo
                          {...generalInfo}
                          streamStartDateTime={eventStreamStartDateTime}
                        />
                      </ErrorBoundary>
                      {generalInfo.wordlist && (
                        <ErrorBoundary component="Wordlist">
                          <Wordlist
                            keyboardShortcutsDispatcher={keyboardShortcutsDispatcher}
                            wordlist={generalInfo.wordlist}
                          />
                        </ErrorBoundary>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </SwateiProvider>
        </FlashMessageProvider>
      )}
    </>
  );
}

LiveEvent.propTypes = {
  audioAccess: PropTypes.shape({
    type: PropTypes.oneOf(['EMBED', 'CDN_FILE_STREAM', 'WEB_RTC']),
    url: PropTypes.string,
    app_name: PropTypes.string,
    stream_name: PropTypes.string,
  }),
  apiPaths: PropTypes.shape({
    addSpeechEngineSessionId: PropTypes.string,
    audioAccess: PropTypes.string,
    reportNoAudio: PropTypes.string,
  }),
  captionFormatConfig: PropTypes.shape({
    characterTarget: PropTypes.number,
  }),
  dataToCaption: PropTypes.exact({
    videoUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        url: PropTypes.string,
        app_name: PropTypes.string,
        stream_name: PropTypes.string,
      }),
    ]),
    isLiveEvent: PropTypes.bool.isRequired,
    isPracticeMode: PropTypes.bool.isRequired,
  }),
  eventStreamStartDateTime: PropTypes.string,
  generalInfo: PropTypes.exact({
    additionalInfo: PropTypes.string,
    captionerJoinTime: PropTypes.string,
    companyName: PropTypes.string,
    description: PropTypes.string,
    estimatedDurationInMS: PropTypes.number,
    eventAccessInfo: PropTypes.string,
    eventId: PropTypes.number,
    eventPublicId: PropTypes.string,
    eventName: PropTypes.string,
    eventType: PropTypes.string,
    genre: PropTypes.string,
    hasLiveStream: PropTypes.bool,
    overtimeOptionText: PropTypes.string,
    cheatSheetPdfLink: PropTypes.string,
    platformName: PropTypes.string,
    platformInstructionsText: PropTypes.string,
    scheduledStartTime: PropTypes.number,
    scheduledEndTime: PropTypes.number,
    scheduledDurationInMS: PropTypes.number,
    speakerNames: PropTypes.arrayOf(PropTypes.string),
    swateiJobId: PropTypes.number,
    useEndEventButton: PropTypes.bool,
    wordlist: PropTypes.arrayOf(PropTypes.string),
    zoomEventJoinUrl: PropTypes.string,
    webpageWithCaptionsUrl: PropTypes.string,
  }),
  msCognitiveServiceKey: PropTypes.string.isRequired,
  msCognitiveServiceEndpointId: PropTypes.string,
  shouldRecordCaptionerAudio: PropTypes.bool,
  speechmaticsConfig: PropTypes.shape({
    additionalVocab: PropTypes.arrayOf(PropTypes.string),
    jwt: PropTypes.string,
    refreshJwtPath: PropTypes.string,
    url: PropTypes.string,
  }),
  userCheckedIn: PropTypes.bool.isRequired,
  zeroOffsetDateTime: PropTypes.string,
};

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: '10px',
  },
});

export default LiveEvent;
