import { Logger } from '~/logic/Logger/Logger';

/**
 * The audio handler for the Speechmatics real-time transcription service
 */
export class SmxAudioHandler {
  /** The logger instance */
  private logger: Logger;

  /** The current media stream */
  private mediaStream: MediaStream | null = null;

  /** The current audio context */
  private audioContext: AudioContext | null = null;

  /** The current source node */
  private sourceNode: MediaStreamAudioSourceNode | null = null;

  /** The current audio worklet node */
  private audioWorkletNode: AudioWorkletNode | null = null;

  /**
   * Constructor for the SmxAudioHandler class
   */
  constructor() {
    this.logger = Logger.getInstance();
  }

  /**
   * Prepare the audio handler
   * @returns A promise that resolves when the audio handler is prepared
   */
  public async prepareAudio(): Promise<void> {
    try {
      // Step 1: Request access to the user's microphone
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Step 2: Create an AudioContext for processing audio
      this.audioContext = new AudioContext();

      // Step 3: Load the AudioWorklet module
      await this.audioContext.audioWorklet.addModule('/swatei/audio-processor.js');

      // Step 4: Create a MediaStreamAudioSourceNode from the media stream
      this.sourceNode = this.audioContext.createMediaStreamSource(this.mediaStream);

      // Step 5: Create an AudioWorkletNode
      this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor');

      // Step 6: Connect the source node to the worklet node
      this.sourceNode.connect(this.audioWorkletNode);

      // Do we need this or will this create feedback for the user?
      // Step 7: Connect the worklet node to the audio context's destination
      this.audioWorkletNode.connect(this.audioContext.destination);
    } catch (error) {
      const isPermissionDenied = error instanceof DOMException && error.name === 'NotAllowedError';
      const isDeviceNotFound = error instanceof DOMException && error.name === 'NotFoundError';

      this.logger.error({
        message: this.formatError(error),
        info: {
          message: 'Error in SmxAudioHandler.prepareAudio()',
        },
      });

      // Show appropriate alert based on error type
      if (isPermissionDenied) {
        window.alert(
          'Microphone access is required for transcription. Please allow microphone access and refresh the page.'
        );
      } else if (isDeviceNotFound) {
        window.alert('No microphone found. Please connect a microphone and refresh the page.');
      } else {
        window.alert(
          'An error occurred while setting up the microphone. Please refresh the page and try again.'
        );
      }

      // Clean up any partially initialized resources
      await this.cleanup();
    }
  }

  /**
   * Get the audio context
   * @returns The audio context
   */
  public getAudioContext(): AudioContext | null {
    return this.audioContext;
  }

  /**
   * Add an event listener for audio data
   * @param callback The callback to call when audio data is received
   */
  public onAudioData(callback: (audioData: ArrayBuffer) => void): void {
    if (this.audioWorkletNode) {
      this.audioWorkletNode.port.onmessage = (event: MessageEvent<Float32Array>) => {
        callback(event.data.buffer);
      };
    }
  }

  /**
   * Cleanup the audio handler
   * @returns A promise that resolves when the audio handler is cleaned up
   */
  public async cleanup(): Promise<void> {
    try {
      if (this.audioWorkletNode) {
        this.audioWorkletNode.port.onmessage = null;
        this.audioWorkletNode.disconnect();
      }
      if (this.sourceNode) {
        this.sourceNode.disconnect();
      }
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (this.audioContext) {
        await this.audioContext.close();
      }
    } catch (error) {
      this.logger.error({
        message: this.formatError(error),
        info: { message: 'Error in SmxAudioHandler.cleanup()' },
      });
    } finally {
      this.mediaStream = null;
      this.audioContext = null;
      this.sourceNode = null;
      this.audioWorkletNode = null;
    }
  }

  /**
   * Format an error
   * @param error The error to format
   * @returns The formatted error
   */
  private formatError(error: unknown) {
    if (error instanceof DOMException) {
      return `${error.name}: ${error.message}`;
    }
    if (error instanceof Error) return error;
    return String(error);
  }
}
