import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import { translationProfileType } from '~/components/app/order_form/propTypes';

const TranslationProfileDropDown = ({
  selectedTranslationProfile,
  setSelectedTranslationProfile,
  targetLanguage,
  translationProfiles,
}) => {
  return (
    <Form.Control
      as="select"
      defaultValue={selectedTranslationProfile}
      onChange={(e) => setSelectedTranslationProfile(e, targetLanguage)}
      size="sm"
    >
      {translationProfiles.map((translationProfile) => {
        if (translationProfile.name) {
          return (
            <option key={translationProfile.id} value={translationProfile.id}>
              {translationProfile.name}
            </option>
          );
        } else if (translationProfile.default) {
          return (
            <option key={translationProfile.id} value={translationProfile.id}>
              {'default'}
            </option>
          );
        } else {
          return (
            <option key={translationProfile.id} value={translationProfile.id}>
              {'Profile ' + translationProfile.id}
            </option>
          );
        }
      })}
    </Form.Control>
  );
};

TranslationProfileDropDown.propTypes = {
  selectedTranslationProfile: PropTypes.number,
  setSelectedTranslationProfile: PropTypes.func,
  targetLanguage: PropTypes.string,
  translationProfiles: PropTypes.arrayOf(translationProfileType),
};

export default TranslationProfileDropDown;
