/**
 * Common (non-printable) keycodes for `keydown` and `keyup` events. Note that
 * `keypress` handles things differently and may not return the same values.
 */
export var BACKSPACE = 8;
export var TAB = 9;
export var RETURN = 13;
export var ESC = 27;
export var SPACE = 32;
export var LEFT = 37;
export var UP = 38;
export var RIGHT = 39;
export var DOWN = 40;
export var DEFAULT_LABELKEY = 'label';
export var ALIGN = {
  JUSTIFY: 'justify',
  LEFT: 'left',
  RIGHT: 'right'
};
export var SIZE = {
  LARGE: 'large',
  LG: 'lg',
  SM: 'sm',
  SMALL: 'small'
};