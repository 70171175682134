import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const CsvActionsDropdown = ({ onUpload, downloadUrl }) => {
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    console.log('File select triggered');
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      console.log('File selected:', selectedFile.name);
      console.log('Calling onUpload with file');
      onUpload(selectedFile);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary">CSV Actions</Dropdown.Toggle>
      <Dropdown.Menu className={css(styles.dropdownMenu)}>
        <Dropdown.Item as="div">
          <a href={downloadUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
            Download CSV Template
          </a>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="div">
          <Form.Label htmlFor="csv-upload" className={css(styles.uploadLabel)}>
            Upload CSV
          </Form.Label>
          <Form.Control
            id="csv-upload"
            ref={fileInputRef}
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            size="sm"
            className={css(styles.hiddenInput)}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

CsvActionsDropdown.propTypes = {
  onUpload: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  dropdownMenu: {
    minWidth: '300px',
    padding: '0.5rem',
  },
  uploadLabel: {
    cursor: 'pointer',
    margin: 0,
    width: '100%',
  },
  hiddenInput: {
    // Hide the default file input but keep it accessible
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
});

export default CsvActionsDropdown;
