/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Template
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface EndOfStream
 */
export interface EndOfStream {
  /**
   *
   * @type {string}
   * @memberof EndOfStream
   */
  message: EndOfStreamMessageEnum;
  /**
   *
   * @type {number}
   * @memberof EndOfStream
   */
  last_seq_no: number;
}

export const EndOfStreamMessageEnum = {
  EndOfStream: 'EndOfStream',
} as const;

export type EndOfStreamMessageEnum =
  (typeof EndOfStreamMessageEnum)[keyof typeof EndOfStreamMessageEnum];
