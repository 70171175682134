import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { Alert } from '@threeplayground/index';

import ReactPlayer from 'react-player';
import { ResizableBox } from 'react-resizable';

import { useAudioRecorder } from './hooks/useAudioRecorder';
import { RailsClient } from './RailsClient';

import { SwateiContext } from './context/Swatei.Context';

import { WebRTCConnection } from './utils/webrtcPlayerUtil';
import { getLocalStorageData, updateLocalStorage } from './utils/localStorageUtil';
import { getCsrfToken } from '~/helpers/authtokenHelper';

import 'react-resizable/css/styles.css';
import './styles.css';
import { CaptionPipeline } from './CaptionPipeline/CaptionPipeline';
import { KinesisClient } from './CaptionPipeline/CaptionSender/KinesisClient';
import { Logger } from '~/logic/Logger/Logger';
import { OutputCaptions } from './OutputCaptions';
import { ReportNoAudioButton } from './ReportNoAudioButton';
import { uniqueId } from 'lodash';

const SPEECH_ENGINE_CONNECTION_TIMEOUT = 5000;

const isHttpsUrl = (url) => {
  // Not a robust regex, but it's good enough for our purposes.
  return /^https:\/\/\w+/.test(url);
};

const VideoPlayer = ({ audioAccess, videoPlaying }) => {
  if (!audioAccess) {
    return <></>;
  }

  const { url, type } = audioAccess;

  if (!url) {
    return <div className="text-white">Waiting for audio stream</div>;
  }

  switch (type) {
    case 'EMBED':
      return isHttpsUrl(url) ? (
        <iframe src={url} height="100%" width="100%" title="Audio Source Embed" />
      ) : (
        // We cannot embed http content on our https page, so we link to it instead.
        <div className="bg-white p-4" title="Audio Source Link">
          <p className="font-weight-bold">Audio Access Link:</p>
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </div>
      );
    case 'WEB_RTC':
      // Currently, the WebRTCConnection class will query the DOM for a
      // <video> element, and will pipe the video stream into that element.
      return (
        <video
          playsInline
          nocontrols="true"
          width="100%"
          height="100%"
          data-testid="video-player"
        ></video>
      );
    case 'CDN_FILE_STREAM':
      return (
        <ReactPlayer
          url={url}
          playing={videoPlaying}
          width="100%"
          height="100%"
          data-testid="video-player"
          onContextMenu={(e) => {
            // NOTE this prevents the menu from popping
            // up when right clicking on the video
            e.preventDefault();
          }}
        />
      );
  }
};

function CaptioningInterface(props) {
  const {
    apiPaths,
    audioAccess,
    captionFormatConfig,
    logSwateiAmplitudeEvent,
    msCognitiveServiceKey,
    msCognitiveServiceEndpointId,
    scheduledStartTime,
    setConnected,
    setSpeechEngineConnected,
    shouldRecordCaptionerAudio,
    speechEngineConnected,
    speechmaticsConfig,
    zeroOffsetDateObject,
  } = props;
  const [internetConnected, setInternetConnected] = useState(navigator.onLine);
  const [alertMessage, setAlertMessage] = useState(undefined);
  const [captionInput, setCaptionInput] = useState('');
  const [speaking, setSpeaking] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({ height: 150, width: 200 });
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [speechEngineSessionData, setSpeechEngineSessionData] = useState({
    sessionId: null,
    speechEngine: null,
  });
  const [captionInputDisabled, setCaptionInputDisabled] = useState(false);
  const captionInputRef = useRef();
  const captionerTypingStartsAt = useRef(undefined);
  const speechConnectingTimer = useRef(undefined);
  const speechDisconnectingTimer = useRef(undefined);

  // The component singleton pattern below guarantees an imperative class instance
  // only gets created once
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  const captionPipeline = useRef(null);
  function getCaptionPipeline() {
    if (captionPipeline.current === null) {
      captionPipeline.current = CaptionPipeline.getInstance({
        amplitudeLogger: logSwateiAmplitudeEvent,
        captionFormatConfig,
        msCognitiveServiceKey,
        msCognitiveServiceEndpointId,
        setCaptionInput,
        setCaptionInputDisabled,
        setSpeechEngineConnected,
        setSpeechEngineSessionData,
        speechmaticsConfig,
        submitCaption,
        wordlist: props.wordlist,
        zeroOffsetDateObject,
      });
    }
    return captionPipeline.current;
  }

  /*
   * outputCaptions need to be refs b/c something in the CaptioningInterface is
   * triggering normal state to get lost during the captioning process.
   *
   * TODO: Figure out what is causing state to be lost and address the issue. Then,
   * switch these reference(s) to use state instead.
   */
  const outputCaptions = useRef([]);
  const videoWrapperRef = useRef();
  const prevSubmissionOffsetRef = useRef(null);

  const xappVideoTimeRef = useRef(getLocalStorageData(props.swateiJobId)?.xappVideoTime || 0);

  const { isLiveEvent, isPracticeMode, streamlessEvent, eventId, eventPublicId } =
    useContext(SwateiContext);

  const Mousetrap = require('mousetrap');

  const kinesisClient = useRef(null);
  function getKinesisClient() {
    if (kinesisClient.current === null) {
      kinesisClient.current = new KinesisClient({
        eventPublicId,
        setConnected,
        swateiJobId: props.swateiJobId,
      });
    }
    return kinesisClient.current;
  }

  // Set focus on the caption input whenever it transitions to enabled
  useEffect(() => {
    if (!captionInputDisabled) {
      captionInputRef.current.focus();
    }
  }, [captionInputDisabled]);

  // Add event listeners for when the captioner loses internet connection
  useEffect(() => {
    const offlineHandler = () => {
      setInternetConnected(false);
    };
    window.addEventListener('offline', offlineHandler);

    const onlineHandler = () => {
      setInternetConnected(true);
    };
    window.addEventListener('online', onlineHandler);

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
    };
  }, []);

  // Stop the speech engine when the captioner loses internet connection
  useEffect(() => {
    if (!internetConnected) {
      Logger.getInstance().info({ message: 'Internet connection lost.' });
      setAlertMessage(
        'Your internet connection was lost. Press "Speak" when your connection is restored.'
      );
      setSpeaking(false);
      setSpeechEngineConnected(false);
    } else {
      setAlertMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetConnected]);

  useEffect(() => {
    const { sessionId, speechEngine } = speechEngineSessionData;
    if (sessionId && apiPaths.addSpeechEngineSessionId) {
      getKinesisClient().speechEngineSessionId = sessionId;
      Logger.getInstance().info({
        message: 'Adding speech engine session ID via Rails API',
        info: {
          sessionId,
          speechEngine,
        },
      });

      RailsClient.addSpeechEngineSessionId(apiPaths.addSpeechEngineSessionId, {
        session_id: sessionId,
        speech_engine: speechEngine,
      }).catch((error) => {
        Logger.getInstance().warn({
          message: 'Failed to add speech engine session id',
          info: { error },
        });
      });
    }
  }, [speechEngineSessionData.sessionId, apiPaths.addSpeechEngineSessionId]);

  useEffect(() => {
    props.speakerNames.map((speaker, index) => {
      const speakerNumber = index === 0 ? '`' : index % 10;
      Mousetrap.bind(`ctrl+${speakerNumber}`, () => {
        if (!captionInputDisabled) {
          submitCaptionsFromTextInput(`${captionInput} [${speaker}]`);
        }
        return false;
      });
    });
    return () => {
      Mousetrap.unbind('ctrl+`');
      props.speakerNames.map((speaker, index) => {
        const speakerNumber = index;
        Mousetrap.unbind(`ctrl+${speakerNumber}`);
      });
    };
  }, [props.speakerNames, captionInputDisabled, captionInput]);

  useEffect(() => {
    props.keyboardShortcuts.map((shortcut) => {
      Mousetrap.bind(`ctrl+${shortcut.character}`, () => {
        if (!captionInputDisabled) {
          submitCaptionsFromTextInput(`${captionInput} ${shortcut.command}`);
        }
        return false;
      });
    });

    return () => {
      props.keyboardShortcuts.map((shortcut) => {
        Mousetrap.unbind(`ctrl+${shortcut.character}`);
      });
    };
  }, [props.keyboardShortcuts, captionInputDisabled, captionInput]);

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.addEventListener('resize', resizePanels);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('resize', resizePanels);
      }
    };
  }, [props.videoUrl]);

  const trackXappVideoProgress = () => {
    const videoElement = document.querySelector('video');

    if (!isLiveEvent && videoElement) {
      videoElement.currentTime = xappVideoTimeRef.current;

      videoElement.ontimeupdate = () => {
        updateLocalStorage(props.swateiJobId, 'xappVideoTime', videoElement.currentTime);
        xappVideoTimeRef.current = videoElement.currentTime;
      };
    }
  };

  useEffect(trackXappVideoProgress, [props.videoUrl]);

  useEffect(() => {
    window.addEventListener('resize', resizePanels);
    return () => {
      window.removeEventListener('resize', resizePanels);
    };
  });

  function resizePanels() {
    const videoElement = document.querySelector('video');
    const width = videoWrapperRef.current?.offsetWidth || 0;
    const height = videoElement?.videoHeight;

    if (width && height) {
      setVideoDimensions({ height: height * 0.25, width: width * 0.25 });
    }
  }

  useEffect(() => {
    // For Xapp, we don't connect to Kinesis
    // Connected defines the state of connection to Kinesis, explicitly setting it to connected when in Xapp mode
    if (isLiveEvent) {
      getKinesisClient().initialize({ startHeartbeat: true });
    } else {
      setConnected(true);
    }
  }, [isLiveEvent, setConnected]);

  useEffect(() => {
    return () => getKinesisClient().stopHeartbeat();
  }, []);

  useEffect(() => {
    if (prevSubmissionOffsetRef.current !== null) {
      return;
    }

    getCaptioningStartTime();
  });

  useEffect(() => {
    if (speaking) {
      Logger.getInstance().info({ message: 'Speaking mode activated' });
      logSwateiAmplitudeEvent('Speaking Mode Activated');
      getCaptionPipeline().startRecognizer();
    } else if (getCaptionPipeline().recognizerStarted) {
      getCaptionPipeline().stopRecognizer();
      Logger.getInstance().info({ message: 'Speaking mode de-activated' });
      logSwateiAmplitudeEvent('Speaking Mode de-activated');
    }
  }, [speaking]);

  useEffect(() => {
    let videoStreamConnection;

    if (audioAccess?.type === 'WEB_RTC') {
      const { url, app_name, stream_name } = audioAccess;
      videoStreamConnection = new WebRTCConnection({
        videoUrl: { url, app_name, stream_name },
        retryConnectInterval: 15000,
      });
      videoStreamConnection.connect();
    }

    return () => {
      if (videoStreamConnection) {
        videoStreamConnection.cleanup();
      }
    };
  }, []);

  const { startRecording, stopRecording } = useAudioRecorder({
    swateiJobId: props.swateiJobId,
    audioFileInterval: 20000,
  });

  function getOffsetReferenceTime() {
    return isLiveEvent ? props.startSendingCaptionsTime : getDateTimeRelativeToVideoPlayheadTime();
  }

  function getDateTimeRelativeToVideoPlayheadTime() {
    const timeNow = new Date();
    return new Date(timeNow.getTime() - xappVideoTimeRef.current * 1000);
  }

  function getCaptioningStartTime() {
    if (xappVideoNotPlaying()) {
      return;
    }

    prevSubmissionOffsetRef.current =
      prevSubmissionOffsetRef.current || new Date() - getOffsetReferenceTime();
  }

  function pushCaptionDataToTranscript(words) {
    // Errors just go to the console, is that right?
    const formData = new FormData();
    formData.append('authenticity_token', getCsrfToken());
    formData.append('words', JSON.stringify(words));
    const url = `/swatei/live_events/${props.swateiJobId}/update_transcript`;
    Logger.getInstance().info({
      message: '3play App update_transcript POST request',
      info: { url, words },
    });
    fetch(url, { headers: { Accept: 'application/json' }, method: 'POST', body: formData })
      .then((response) => response.json())
      .then((response) => {
        const logger = Logger.getInstance();

        if (response.redirect) {
          logger.warn({
            message: '3play App update_transcript triggered redirect',
            info: { response },
          });
          window.skipConfirmLeavePage = true;
          window.location.replace(response.redirect);
          return;
        }
        logger.info({
          message: '3play App update_transcript POST response',
          info: { response },
        });
      });
  }

  function handleMicToggle() {
    getCaptioningStartTime();

    if (shouldRecordCaptionerAudio && !speaking) {
      startRecording();
    } else if (speaking) {
      stopRecording();
    }
    // toggle speaking which triggers effect
    setSpeaking(!speaking);
  }

  function handleOnChange(event) {
    event.persist();
    submitCaptionsFromTextInput(event.target.value);
  }

  function submitCaptionsFromTextInput(captions) {
    if (captionInput === '') {
      captionerTypingStartsAt.current = Date.now();
    }
    getCaptionPipeline().addInputTextToBuffer(captions);
  }

  const xappVideoNotPlaying = () => {
    return !isPracticeMode && !props.isLiveEvent && xappVideoTimeRef.current === 0;
  };

  const shouldSubmitCaptions = (isPracticeMode, isStreamlessEvent, eventHasStarted) => {
    // Never for practice mode; Always for Streamless; After event started for streamed or XApp
    return !isPracticeMode && (isStreamlessEvent || eventHasStarted);
  };

  function getSegmentTimingData() {
    const submissionTime = new Date();
    const offsetReferenceTime = getOffsetReferenceTime();
    const submissionOffset = offsetReferenceTime ? submissionTime - offsetReferenceTime : 0;
    const segmentStartTime = prevSubmissionOffsetRef.current;
    const segmentDuration = submissionOffset - segmentStartTime;
    return { submissionOffset, segmentStartTime, segmentDuration };
  }

  /**
   * Submits the caption to the appropriate "handlers"
   * @param {import('./CaptionPipeline/CaptionCreator/Caption').Caption} caption
   * @returns {void}
   */
  function submitCaption(caption) {
    // This is here to help text the caption output data in deployed environments
    Logger.getInstance().info({
      message: 'submitCaption',
      info: { caption },
    });
    if (!isLiveEvent && xappVideoNotPlaying()) {
      return;
    }

    // This won't be identical to the offset used in the CaptionCreator,
    // but that's ok since it's only used for eventHasStarted
    const { submissionOffset } = getSegmentTimingData();

    if (shouldSubmitCaptions(isPracticeMode, streamlessEvent, submissionOffset > 0)) {
      pushCaptionDataToTranscript(caption.dataForTranscript);
    }

    if (isLiveEvent) {
      const kinesisClient = getKinesisClient();
      if (kinesisClient.ready()) {
        kinesisClient.send(caption);
      } else {
        Logger.getInstance().warn({
          message: 'submitCaption Kinesis not set up. Retrying',
          info: {
            kinesisSetup: !!kinesisClient.client,
            shardInfoPresent: !!kinesisClient.currentShard,
            streamNamePresent: !!kinesisClient.streamName,
          },
        });
        logSwateiAmplitudeEvent('Kinesis Setup Failure', {
          kinesisSetup: !!kinesisClient.client,
          shardInfoPresent: !!kinesisClient.currentShard,
          streamNamePresent: !!kinesisClient.streamName,
        });
        kinesisClient.initialize();
      }
    }

    outputCaptions.current = [
      ...outputCaptions.current,
      { content: caption.captionText, id: uniqueId() },
    ];
    setCaptionInput('');
    prevSubmissionOffsetRef.current = submissionOffset;
  }

  function startVideo() {
    setVideoPlaying(true);
  }

  const connectingToSpeechEngine = !speechEngineConnected && speaking;

  const disconnectingFromSpeechEngine = speechEngineConnected && !speaking;

  useEffect(() => {
    if (connectingToSpeechEngine) {
      speechConnectingTimer.current = setTimeout(() => {
        setAlertMessage('Failed to connect to the speech engine. Please refresh and try again.');
        Logger.getInstance().warn({ message: 'Failed to connect to the speech engine.' });
      }, SPEECH_ENGINE_CONNECTION_TIMEOUT);
    } else {
      clearTimeout(speechConnectingTimer.current);
      setAlertMessage(undefined);
    }
    return () => clearTimeout(speechConnectingTimer.current);
  }, [connectingToSpeechEngine]);

  useEffect(() => {
    if (disconnectingFromSpeechEngine) {
      speechDisconnectingTimer.current = setTimeout(() => {
        setAlertMessage(
          'Failed to disconnect from the speech engine. Please refresh before attempting to reconnect.'
        );
        Logger.getInstance().warn({ message: 'Failed to disconnect from the speech engine.' });
      }, SPEECH_ENGINE_CONNECTION_TIMEOUT);
    } else {
      clearTimeout(speechDisconnectingTimer.current);
      setAlertMessage(undefined);
    }
    return () => clearTimeout(speechDisconnectingTimer.current);
  }, [disconnectingFromSpeechEngine]);

  function getSpeechButtonText() {
    if (connectingToSpeechEngine) return 'Connecting...';
    if (disconnectingFromSpeechEngine) return 'Stopping...';
    return speaking ? 'Stop' : 'Speak';
  }

  return (
    <>
      {props.eventAccessInfo && (
        <Row>
          <Col className="mb-3">
            <ResizableBox
              axis="y"
              height={videoDimensions.height}
              // https://github.com/react-grid-layout/react-resizable/issues/69#issuecomment-321082718
              width={Infinity}
              minConstraints={[75, 150]}
              maxConstraints={[Infinity, 500]}
              handle={(h) => (
                <span className={`custom-handle custom-handle-dark custom-handle-${h}`} />
              )}
              resizeHandles={['s']}
            >
              <div
                className={`${css([
                  styles.overflowYAuto,
                  styles.overflowWrapBreak,
                ])} bg-secondary w-100 h-100 p-4 text-white`}
              >
                <h5 className="text-center">
                  Event audio/video must be accessed using the following customer-provided
                  information:
                </h5>
                <pre className={`${css(styles.fontInherit)} text-white`}>
                  {props.eventAccessInfo}
                </pre>
              </div>
            </ResizableBox>
          </Col>
        </Row>
      )}

      {!streamlessEvent && (
        <Row
          className={`${css(styles.box)} justify-content-center w-100 m-0 p-2`}
          id="video-wrapper"
          ref={videoWrapperRef}
        >
          <ResizableBox
            className="d-flex justify-content-center align-items-center"
            height={videoDimensions.height}
            width={videoDimensions.width}
            lockAspectRatio={true}
            minConstraints={[100, 75]}
            handle={(h) => <span className={`custom-handle custom-handle-${h}`} />}
            resizeHandles={['sw', 'se']}
          >
            {!isPracticeMode && (
              <VideoPlayer audioAccess={audioAccess} videoPlaying={videoPlaying} />
            )}
          </ResizableBox>
        </Row>
      )}

      {!isPracticeMode && !isLiveEvent && (
        <Row className="w-100 m-0">
          <Button
            variant="primary"
            type="button"
            data-testid="startVideoButton"
            id="startVideoButton"
            className="mb-3"
            onClick={() => startVideo()}
          >
            Start Video
          </Button>
        </Row>
      )}

      <ResizableBox
        axis="y"
        className="justify-content-center"
        height={250}
        // https://github.com/react-grid-layout/react-resizable/issues/69#issuecomment-321082718
        width={Infinity}
        minConstraints={[75, 75]}
        handle={(h) => <span className={`custom-handle custom-handle-dark custom-handle-${h}`} />}
        resizeHandles={['s']}
      >
        <Row className={`${css(styles.captionBox)} m-0 mt-2`} data-testid="caption-output">
          <OutputCaptions captions={outputCaptions.current} />
        </Row>
      </ResizableBox>

      <Row className="m-0">
        <Form className="w-100">
          <Form.Group controlId="captions">
            <Form.Control
              className={`mousetrap ${css(styles.captionInput)}`}
              aria-label="captions"
              name="captions"
              as="textarea"
              onChange={handleOnChange}
              onClick={getCaptioningStartTime}
              value={captionInput}
              disabled={captionInputDisabled}
              ref={captionInputRef}
            />
          </Form.Group>
          <Row>
            <Col>
              <Button
                variant={speechEngineConnected ? 'danger' : 'primary'}
                type="button"
                id="startRecognizeOnceAsyncButton"
                onClick={handleMicToggle}
                disabled={
                  connectingToSpeechEngine || disconnectingFromSpeechEngine || !internetConnected
                }
              >
                {getSpeechButtonText()}
              </Button>
            </Col>
            <Col className="text-right">
              <ReportNoAudioButton
                reportNoAudioPath={isLiveEvent ? apiPaths.reportNoAudio : undefined}
                scheduledStartTimeISO={
                  scheduledStartTime ? new Date(scheduledStartTime * 1000).toISOString() : null
                }
                submitCaption={submitCaptionsFromTextInput}
              />
            </Col>
          </Row>
        </Form>
      </Row>

      <div className={css(styles.alertContainer)}>
        {alertMessage && (
          <Alert variant="danger" onClose={() => setAlertMessage(undefined)}>
            {alertMessage}
          </Alert>
        )}
      </div>
    </>
  );
}

const audioAccessPropType = PropTypes.shape({
  type: PropTypes.oneOf(['EMBED', 'CDN_FILE_STREAM', 'WEB_RTC']),
  url: PropTypes.string,
  app_name: PropTypes.string,
  stream_name: PropTypes.string,
});

CaptioningInterface.propTypes = {
  apiPaths: PropTypes.shape({
    addSpeechEngineSessionId: PropTypes.string,
    audioAccess: PropTypes.string,
    reportNoAudio: PropTypes.string,
  }),
  audioAccess: audioAccessPropType,
  captionFormatConfig: PropTypes.shape({
    characterTarget: PropTypes.number,
  }),
  swateiJobId: PropTypes.number.isRequired,
  // eventId: PropTypes.number.isRequired,
  videoUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string,
      app_name: PropTypes.string,
      stream_name: PropTypes.string,
    }),
  ]),
  isLiveEvent: PropTypes.bool.isRequired,
  setConnected: PropTypes.func.isRequired,
  speechEngineConnected: PropTypes.bool,
  setSpeechEngineConnected: PropTypes.func,
  startSendingCaptionsTime: PropTypes.instanceOf(Date).isRequired,
  eventAccessInfo: PropTypes.string,
  speakerNames: PropTypes.arrayOf(PropTypes.string),
  keyboardShortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      character: PropTypes.string,
      command: PropTypes.string,
      editing: PropTypes.bool,
    })
  ),
  logSwateiAmplitudeEvent: PropTypes.func,
  wordlist: PropTypes.arrayOf(PropTypes.string),
  msCognitiveServiceKey: PropTypes.string.isRequired,
  msCognitiveServiceEndpointId: PropTypes.string.isRequired,
  shouldRecordCaptionerAudio: PropTypes.bool,
  speechmaticsConfig: PropTypes.shape({
    additionalVocab: PropTypes.arrayOf(PropTypes.string),
    jwt: PropTypes.string,
    refreshJwtPath: PropTypes.string,
    url: PropTypes.string,
  }),
  zeroOffsetDateObject: PropTypes.instanceOf(Date),
  scheduledStartTime: PropTypes.string,
};

VideoPlayer.propTypes = {
  audioAccess: audioAccessPropType,
  videoPlaying: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  alertContainer: {
    marginTop: '1rem',
  },
  box: {
    backgroundColor: '#000',
  },
  captionInput: {
    marginTop: '24px',
  },
  captionBox: {
    border: '1px solid #DBDBDB',
    padding: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column-reverse',
    fontSize: '20px',
    fontFamily: 'Lucida Grande',
    letterSpacing: '0px',
    color: '#0F0909',
    height: '100%',
    width: '100%',
  },
  captionLogLine: {
    marginBottom: '4px',
    paddingBottom: '4px',
  },
  fontInherit: {
    font: 'inherit',
  },
  overflowYAuto: {
    overflowY: 'auto',
  },
  overflowWrapBreak: {
    overflowWrap: 'break-word',
  },
  recentlySubmitted: {
    backgroundColor: '#EEF7FA',
  },
});

export default CaptioningInterface;
