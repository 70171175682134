import React, { useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import {
  selectedServicesType,
  subtitlingOrderOptionsType,
} from '~/components/app/order_form/propTypes';
import ServiceCard from '~/components/app/order_form/ServiceCard';
import AudioDescriptionOrderOptions from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';
import BroadcastScriptOrderOptions from '~/components/app/order_form/serviceOptions/BroadcastScriptOrderOptions';
import CaptionPlacementOrderOptions from '~/components/app/order_form/serviceOptions/CaptionPlacementOrderOptions';
import DescriptiveTranscriptOrderOptions from '~/components/app/order_form/serviceOptions/DescriptiveTranscriptOrderOptions';
import DubbingOrderOptions from '~/components/app/order_form/serviceOptions/DubbingOrderOptions';
import SubtitlingOrderOptions from '~/components/app/order_form/serviceOptions/SubtitlingOrderOptions';
import TranslationOrderOptions from '~/components/app/order_form/serviceOptions/TranslationOrderOptions';
import { ProjectContext } from '../OrderForm';

import {
  AI_DUBBING_ONLY_AFTER_TRANSCRIPTION_TOOLTIP,
  AI_DUBBING_TRANSLATION_PROFILE_MISSING_TOOLTIP,
  DUBBING_ONLY_ENGLISH_TOOLTIP,
  ENGLISH_ID,
  ENGLISH_UK_ID,
  SPANISH_ID,
  PRIMARY_SERVICES,
  SERVICE_START_INDEX,
  ONLY_ENGLISH_AD_ALLOWED_TOOLTIP,
  ONLY_ENGLISH_AND_SPANISH_AD_ALLOWED_TOOLTIP,
  SCRIPTING_SERVICE_REQUIREMENTS_TOOLTIP,
  TRANSLATION_NOT_ALLOWED_TOOLTIP,
  TRANSLATION_PROFILE_MISSING_TOOLTIP,
  VALID_AD_LANGUAGE_IDS,
} from '~/helpers/constants';
import { userLogger } from '~/logic/UserLogger';

function AdditionalServices({
  acceptedWarningsDispatch,
  acceptedWarningsState,
  availableAdditionalServices,
  availableTranslationOptionsForLanguages,
  cheatSheetComponent,
  defaultTranslationProfileId,
  defaultTranslationVendorId,
  refusedSourceLanguageTemplate,
  selectedServices,
  selectedTranslationLanguages,
  selectedTranslationType,
  setAvailableTranslationOptionsForLanguages,
  setRefusedSourceLanguageTemplate,
  setSelectedServices,
  setSelectedTranslationLanguages,
  setSelectedTranslationType,
  setShowAudioMixingOptions,
  setTranslationSelectedOrderOptions,
  setTranslationVendorForInfo,
  setValidDubbingQuote,
  showAudioMixingOptions,
  sourceLanguage,
  translationOrderOptions,
  translationProfiles,
  translationSelectedOrderOptions,
  translationTypes,
  updateOrderOptions,
  validDubbingQuote,
}) {
  const { accountType, features } = useContext(ProjectContext);
  useEffect(() => {
    userLogger.logEvent('NewOrder', 'Choose Additional Services Page', {});
  }, []);

  useEffect(() => {
    // Removes any services in "errored" state if any selections change
    selectedServices.map((service) => {
      if (serviceTypeError(service.serviceType)) {
        removeSelectedService(service.serviceType);
      }
    });
  }, [selectedServices]);

  useEffect(() => {
    const templateServiceExists = selectedServices.some(
      (s) => s.serviceType === 'SourceLanguageTemplate'
    );

    if (
      selectedTranslationType === 'Media/Entertainment (Non-SDH subtitles)' &&
      !refusedSourceLanguageTemplate
    ) {
      const templatePrice = availableAdditionalServices.find(
        (as) => as.serviceType === 'SourceLanguageTemplate'
      )?.price;
      if (!templateServiceExists) {
        addSelectedService('SourceLanguageTemplate', templatePrice, {});
      }
    } else {
      removeSelectedService('SourceLanguageTemplate');
    }
  }, [refusedSourceLanguageTemplate, selectedTranslationType]);

  function serviceTypeError(serviceType) {
    switch (serviceType) {
      case 'AudioDescription':
        return audioDescriptionError();
      case 'BroadcastScript':
        return broadcastScriptError();
      case 'Dubbing':
        return dubbingError();
      case 'Translation':
        return translationError();
    }
  }

  function incompatiblePrimaryService() {
    return !selectedServices.some((service) => service.serviceType === 'Transcription');
  }

  function notEnglish() {
    return sourceLanguage.length > 1 || sourceLanguage[0] !== ENGLISH_ID;
  }

  function audioDescriptionError() {
    const audioDescriptionErrorTooltip = features.spanishAudioDescription
      ? ONLY_ENGLISH_AND_SPANISH_AD_ALLOWED_TOOLTIP
      : ONLY_ENGLISH_AD_ALLOWED_TOOLTIP;
    return invalidADLanguage() ? audioDescriptionErrorTooltip : '';
  }

  function invalidADLanguage() {
    if (sourceLanguage.length > 2) {
      return true;
    }

    /**
     * Customers with Dual-Language Transcription enabled can order Audio Description. The Audio Description will use
     * the first of the dual languages: English/Spanish gets English AD, Spanish/English gets Spanish AD.
     * For this latter case, the customer must also have Spanish Audio Description enabled.
     */
    if (sourceLanguage.length === 2) {
      if (features.dualLanguageTranscription) {
        // If they have Spanish Audio Description, then they can order AD for Spanish/English or English/Spanish
        if (features.spanishAudioDescription) {
          return !(
            VALID_AD_LANGUAGE_IDS.includes(sourceLanguage[0]) &&
            VALID_AD_LANGUAGE_IDS.includes(sourceLanguage[1])
          );
        }

        // Otherwise, they can only order English AD, meaning they must select English/Spanish over Spanish/English
        return !(sourceLanguage[0] === ENGLISH_ID && sourceLanguage[1] === SPANISH_ID);
      }

      return true;
    }

    if (features.spanishAudioDescription) {
      return !VALID_AD_LANGUAGE_IDS.includes(sourceLanguage[0]);
    }

    return ![ENGLISH_ID, ENGLISH_UK_ID].includes(sourceLanguage[0]);
  }

  function broadcastScriptError() {
    const broadcastScriptsInvalid = incompatiblePrimaryService() || notEnglish();
    return broadcastScriptsInvalid ? SCRIPTING_SERVICE_REQUIREMENTS_TOOLTIP : '';
  }

  function dubbingError() {
    if (accountType.studio) {
      // Human Dubbing
      return notEnglish() ? DUBBING_ONLY_ENGLISH_TOOLTIP : '';
    } else {
      // AI Dubbing
      if (notEnglish()) {
        return DUBBING_ONLY_ENGLISH_TOOLTIP;
      }
      if (incompatiblePrimaryService()) {
        return AI_DUBBING_ONLY_AFTER_TRANSCRIPTION_TOOLTIP;
      }
      if (noTranslationProfilesPresent()) {
        return AI_DUBBING_TRANSLATION_PROFILE_MISSING_TOOLTIP;
      }
      return '';
    }
  }

  function noTranslationLanguageOptionsPresent() {
    return translationOrderOptions.length === 0;
  }

  function noTranslationProfilesPresent() {
    return translationProfiles.length === 0;
  }

  function translationError() {
    if (noTranslationLanguageOptionsPresent()) {
      return TRANSLATION_NOT_ALLOWED_TOOLTIP;
    } else if (noTranslationProfilesPresent()) {
      return TRANSLATION_PROFILE_MISSING_TOOLTIP;
    }
    return '';
  }

  function toggleService(serviceType, price, orderOptions) {
    const serviceAlreadyIncluded = selectedServices.some((s) => s.serviceType === serviceType);
    if (serviceAlreadyIncluded) {
      removeSelectedService(serviceType);
    } else {
      addSelectedService(serviceType, price, orderOptions);
    }
  }

  function removeSelectedService(serviceType) {
    setSelectedServices(selectedServices.filter((s) => s.serviceType != serviceType));
  }

  function addSelectedService(serviceType, price, orderOptions) {
    const service = availableAdditionalServices.find(
      (service) => service.serviceType === serviceType
    );
    const description = service.description;
    const displayName = service.displayName;
    const serviceInfo = {
      serviceType: serviceType,
      displayName: displayName,
      price: price,
      orderOptions: orderOptions,
      primary: false,
      description: description,
    };
    setSelectedServices(selectedServices.concat(serviceInfo));
  }

  const selectedPrimaryService = selectedServices.filter((service) => {
    return PRIMARY_SERVICES.includes(service.serviceType);
  })[0];
  const selectedLanguage =
    selectedPrimaryService.orderOptions.language && selectedPrimaryService.orderOptions.language;
  const selectedLanguageName = selectedLanguage == null ? '' : selectedLanguage.fullName;

  const audioDescriptionLanguage = () => {
    if (selectedLanguage && selectedLanguage.ids.length === 2) {
      if (selectedLanguage.ids[0] === ENGLISH_ID) {
        return 'English';
      }

      if (selectedLanguage.ids[0] === SPANISH_ID) {
        return 'Spanish (Latin America)';
      }
    }

    return selectedLanguageName;
  };

  return (
    <>
      <div className={css(styles.flex)}>
        {availableAdditionalServices
          ?.filter(function (service) {
            return [
              'AudioDescription',
              'BroadcastScript',
              'CaptionPlacement',
              'DescriptiveTranscript',
              'Dubbing',
              'Subtitling',
              'Translation',
            ].includes(service.serviceType);
          })
          .map((service, index) => (
            <ServiceCard
              key={service.serviceType}
              displayName={service.displayName}
              description={service.description}
              index={index + SERVICE_START_INDEX}
              price={service.price}
              selectedServices={selectedServices}
              serviceType={service.serviceType}
              serviceDisabledTooltip={serviceTypeError(service.serviceType)}
              setSelectedServices={setSelectedServices}
              toggleService={toggleService}
              role="checkbox"
            />
          ))}
      </div>
      {selectedServices.some((service) => service.serviceType === 'AudioDescription') && (
        <AudioDescriptionOrderOptions
          cheatSheetComponent={cheatSheetComponent}
          orderOptions={
            selectedServices.find((s) => s.serviceType === 'AudioDescription').orderOptions
          }
          selectedLanguage={audioDescriptionLanguage()}
          selectedPrimaryService={selectedPrimaryService}
          setShowAudioMixingOptions={setShowAudioMixingOptions}
          setVoiceArtistAdWarningSelected={() =>
            acceptedWarningsDispatch({ type: 'toggle', warning: 'voiceArtistAD' })
          }
          showAudioMixingOptions={showAudioMixingOptions}
          updateOrderOptions={updateOrderOptions}
          voiceArtistAdWarningSelected={acceptedWarningsState.voiceArtistAD}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'CaptionPlacement') && (
        <CaptionPlacementOrderOptions
          orderOptions={
            selectedServices.find((s) => s.serviceType === 'CaptionPlacement').orderOptions
          }
          selectedPrimaryService={selectedPrimaryService}
          updateOrderOptions={updateOrderOptions}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'Translation') && (
        <TranslationOrderOptions
          availableOptionsForLanguages={availableTranslationOptionsForLanguages}
          addSelectedService={addSelectedService}
          defaultTranslationProfileId={defaultTranslationProfileId}
          defaultTranslationVendorId={defaultTranslationVendorId}
          isManagedAccount={accountType.managed}
          orderOptions={
            selectedServices.find((service) => service.serviceType === 'Translation').orderOptions
          }
          removeSelectedService={removeSelectedService}
          refusedSourceLanguageTemplate={refusedSourceLanguageTemplate}
          sourceLanguage={selectedLanguage}
          selectedLanguages={selectedTranslationLanguages}
          selectedOrderOptions={translationSelectedOrderOptions}
          selectedTranslationType={selectedTranslationType}
          setAvailableOptionsForLanguages={setAvailableTranslationOptionsForLanguages}
          setRefusedSourceLanguageTemplate={setRefusedSourceLanguageTemplate}
          setSelectedLanguages={setSelectedTranslationLanguages}
          setSelectedOrderOptions={setTranslationSelectedOrderOptions}
          setSelectedTranslationType={setSelectedTranslationType}
          setTranslationVendorForInfo={setTranslationVendorForInfo}
          setTranslationWarningSelected={() =>
            acceptedWarningsDispatch({ type: 'toggle', warning: 'translation' })
          }
          translationOrderOptions={translationOrderOptions}
          translationProfiles={translationProfiles}
          translationTypes={translationTypes}
          translationWarningSelected={acceptedWarningsState.translation}
          updateOrderOptions={updateOrderOptions}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'BroadcastScript') && (
        <BroadcastScriptOrderOptions
          cheatSheetComponent={cheatSheetComponent}
          orderOptions={
            selectedServices.find((service) => service.serviceType === 'BroadcastScript')
              .orderOptions
          }
          primaryService={selectedPrimaryService}
          selectedLanguage={selectedLanguageName}
          setWarningSelected={() =>
            acceptedWarningsDispatch({ type: 'toggle', warning: 'broadcastScripts' })
          }
          updateOrderOptions={updateOrderOptions}
          warningSelected={acceptedWarningsState.broadcastScripts}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'Dubbing') && (
        <DubbingOrderOptions
          cheatSheetComponent={cheatSheetComponent}
          dubbingWarningSelected={acceptedWarningsState.dubbing}
          selectedServices={selectedServices}
          setDubbingWarningSelected={() =>
            acceptedWarningsDispatch({ type: 'toggle', warning: 'dubbing' })
          }
          setValidDubbingQuote={setValidDubbingQuote}
          translationProfiles={translationProfiles}
          updateOrderOptions={updateOrderOptions}
          validDubbingQuote={validDubbingQuote}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'DescriptiveTranscript') && (
        <DescriptiveTranscriptOrderOptions
          cheatSheetComponent={cheatSheetComponent}
          selectedServices={selectedServices}
          sourceLanguage={selectedLanguage}
          updateOrderOptions={updateOrderOptions}
        />
      )}
      {selectedServices.some((service) => service.serviceType === 'Subtitling') && (
        <SubtitlingOrderOptions
          selectedServices={selectedServices}
          updateOrderOptions={updateOrderOptions}
        />
      )}
    </>
  );
}

AdditionalServices.propTypes = {
  acceptedWarningsDispatch: PropTypes.func,
  acceptedWarningsState: PropTypes.shape({
    broadcastScripts: PropTypes.bool,
    dubbing: PropTypes.bool,
    translation: PropTypes.bool,
    voiceArtistAD: PropTypes.bool,
  }),
  availableAdditionalServices: PropTypes.arrayOf(
    PropTypes.shape({
      serviceType: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        unit: PropTypes.string,
      }),
      description: PropTypes.string,
    })
  ),
  availableTranslationOptionsForLanguages: PropTypes.object,
  cheatSheetComponent: PropTypes.func,
  defaultTranslationProfileId: PropTypes.string,
  defaultTranslationVendorId: PropTypes.string,
  refusedSourceLanguageTemplate: PropTypes.bool,
  selectedServices: selectedServicesType,
  selectedTranslationLanguages: PropTypes.arrayOf(PropTypes.string),
  selectedTranslationType: PropTypes.string,
  setAvailableTranslationOptionsForLanguages: PropTypes.func,
  setRefusedSourceLanguageTemplate: PropTypes.func,
  setSelectedServices: PropTypes.func,
  setSelectedTranslationLanguages: PropTypes.func,
  setSelectedTranslationType: PropTypes.func,
  setShowAudioMixingOptions: PropTypes.func,
  setTranslationSelectedOrderOptions: PropTypes.func,
  setTranslationVendorForInfo: PropTypes.func,
  setValidDubbingQuote: PropTypes.func,
  showAudioMixingOptions: PropTypes.bool,
  sourceLanguage: PropTypes.arrayOf(PropTypes.number),
  subtitlingOrderOptions: subtitlingOrderOptionsType,
  translationOrderOptions: PropTypes.array,
  translationProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      default: PropTypes.bool,
      name: PropTypes.string,
    })
  ),
  translationSelectedOrderOptions: PropTypes.array,
  translationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  updateOrderOptions: PropTypes.func,
  validDubbingQuote: PropTypes.bool,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
  },
});

export default AdditionalServices;
